import { AxiosInstance } from "axios";
import axios from 'axios';
import ClientAPI from "./ClientAPI";

export default class FileDictionaryService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false,
        })
    }

    async getFdict(client: any, id: string) {
        return ClientAPI.Get(`fdict/${id}`, { client })
    }

}
