import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import LogControlState from "@/types/controls/log";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: LogControlState = {
  types_items: [],
  control_id: "",
};

export const getters: GetterTree<LogControlState, RootState> = {
  getTypes: (state) => {
    return state.types_items;
  },
};

export const mutations: MutationTree<LogControlState> = {
  SET_DATA(state, logControl) {
    state.types_items = logControl.types_items;
    state.control_id = logControl.control_id;
  },
};

export const actions: ActionTree<LogControlState, RootState> = {
  async getLogControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.types_items.length > 0) {
        resolve(state);
      } else {
        controlService.getControl("LOG", "LOG.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if(response.length > 0) {
              commit("SET_DATA", response[0]);
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const logControl: Module<LogControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
