import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import WorkOrderState from "@/types/state/workOrder";
import PickerService from "@/services/PickerService";
import WorkOrderService from "@/services/WorkOrderService";

const namespaced = true;
const service = new PickerService(process.env.VUE_APP_ABSTRACTION_API);
const WOService = new WorkOrderService(process.env.VUE_APP_ABSTRACTION_API);
export const state: WorkOrderState = {
  wo_id: "",
  wo_date: "",
  part_no: "",
  wo_qty: "",
  qty_comp: "",
  status: "",
  wip_loc: "",
  rel_date: "",
  req_date: "",
  model_number: "",
  description: "",
  schedule_date: "",
  pick: null,
  oper_items: null,
};

export const getters: GetterTree<WorkOrderState, RootState> = {
  getPicker(state) {
    return state.pick;
  },
  getWorkOrder(state) {
    return state;
  },
  getStatus(state) {
    return state.status;
  },
  getId(state) {
    return state.wo_id;
  },  
};
export const mutations: MutationTree<WorkOrderState> = {
  SET_WO(state, wo) {
    state.wo_id = wo.wo_id;
    state.wo_date = wo.wo_date;
    state.part_no = wo.part_no;
    state.wo_qty = wo.wo_qty;
    state.qty_comp = wo.qty_comp;
    state.status = wo.status;
    state.wip_loc = wo.wip_loc;
    state.rel_date = wo.rel_date;
    state.req_date = wo.req_date;
    state.model_number = wo.model_number;
    state.description = wo.description;
    state.schedule_date = wo.schedule_date;
    state.oper_items = wo.oper_items;
  },
  SET_PICK(state, pick) {
    state.pick = pick;
    if (state.pick?.li_no_items != null) {
      state.pick.li_no_items.forEach((item) => {
        item.bin_number = "";
        item.pull_lot = "";
      });
    }
  },  
};

export const actions: ActionTree<WorkOrderState, RootState> = {
  async fetchPick({ commit, dispatch, state }, { id, client }) {
    return new Promise((resolve, reject) => {
      service
        .getPicker(id, client)
        .then((response: any) => {
          commit("SET_PICK", response);
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  async fetchOrder({ commit, dispatch }, { id, client }) {
    return new Promise((resolve, reject) => {
      WOService.getOrder(id, client)
        .then((response: any) => {
          commit("SET_WO", response);
          resolve("Success");
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async fetchOrders({ commit, state }, { ScheduledWorkcenter, Status }) {
    return new Promise((resolve, reject) => {
      WOService.getOrders({ScheduledWorkcenter, Status})
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
  async putOrders({ commit, state },payload) {

    return new Promise((resolve, reject) => {
    
    WOService
      .putOrders(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject("error");
      });
  });
},

  postPick({ commit, dispatch, state }, { client, pickRec }) {
    return new Promise((resolve, reject) => {
      service
        .putPicker(state.wo_id, client, pickRec)
        .then((response) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject("error");
        });
    });
  },
};

export const workorder: Module<WorkOrderState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
