export default class dbStore {
    private database?: IDBDatabase;
    private databaseName: string;
    private objectStores = [] as {name: string, keyPath: string}[];

    constructor(databaseName: string, objectStores: {name: string, keyPath: string}[]) {
        this.databaseName = databaseName;
        this.objectStores = objectStores;
    }

    async getDatabase(version = 1): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            if (this.database) {
                resolve(this.database)
            }

            const request = window.indexedDB.open(this.databaseName, version);

            request.onerror = (event) => {
                reject(event)
            }

            request.onsuccess = (event) => {
                this.database = request.result;
                resolve(this.database)
            }

            request.onupgradeneeded = (event) => {
                const database = request.result;
                this.objectStores.forEach(store => {
                    if (!database.objectStoreNames.contains(store.name)) {
                        database.createObjectStore(store.name, { keyPath: store.keyPath })
                    }
                })
            }
        })
    }

    async searchRecords(storeName: string, queryHandler: (value: any) => boolean, rangeStart?: number, rangeEnd?: number) {
        this.database = await this.getDatabase();
        return new Promise((resolve, reject) => {
            const transaction = this.database!.transaction(storeName, "readonly");
            const store = transaction.objectStore(storeName);
            const request = store.openCursor();

            const results: any[] = [];
            let count = 0;
            const maxCount = (rangeStart && rangeEnd) ? rangeEnd - rangeStart : 0;
            request.onsuccess = (event) => {
                const cursor = request.result;
                if (cursor) {
                    if (queryHandler(cursor.value)) {
                        count++;
                        if (rangeStart && count >= rangeStart) {
                            results.push(cursor.value);
                        } else if (!rangeStart) {
                            results.push(cursor.value);
                        }
                    }
                    
                    if (rangeEnd && results.length > maxCount) {
                        resolve(results);
                    } else {
                        cursor.continue();
                    }
                } else {
                    resolve(results);
                }
            }

            request.onerror = (event) => {
                reject(event)
            }
        })
    }

    async getRecords(storeName: string, query?: any, count?: number) {
        this.database = await this.getDatabase();
        return new Promise((resolve, reject) => {
            const transaction = this.database!.transaction(storeName, "readonly");
            const store = transaction.objectStore(storeName);
            const request = store.getAll(query, count);

            request.onsuccess = (event) => {
                resolve(request.result)
            }

            request.onerror = (event) => {
                reject(event)
            }
        })
    }

    async getRecord(storeName: string, id: string) {
        this.database = await this.getDatabase();
        return new Promise((resolve, reject) => {
            const transaction = this.database!.transaction(storeName, "readonly");
            const store = transaction.objectStore(storeName);
            const request = store.get(id);

            request.onsuccess = (event) => {
                resolve(request.result)
            }

            request.onerror = (event) => {
                reject(event)
            }
        })
    }

    async putRecord(storeName: string, record: any) {
        this.database = await this.getDatabase();
        return new Promise((resolve, reject) => {
            const transaction = this.database!.transaction(storeName, "readwrite");
            const store = transaction.objectStore(storeName);
            const request = store.put(record);

            request.onsuccess = (event) => {
                resolve(request.result)
            }

            request.onerror = (event) => {
                reject(event)
            }
        })
    }

    async putRecords(storeName: string, records: any[]) {
        this.database = await this.getDatabase();
        return new Promise((resolve, reject) => {
            const transaction = this.database!.transaction(storeName, "readwrite");
            const store = transaction.objectStore(storeName);
            records.forEach(record => {
                store.put(record);
            })

            transaction.oncomplete = (event) => {
                resolve(event)
            }

            transaction.onerror = (event) => {
                reject(event)
            }
        })
    }

    async deleteRecord(storeName: string, id: string) {
        this.database = await this.getDatabase();
        return new Promise((resolve, reject) => {
            const transaction = this.database!.transaction(storeName, "readwrite");
            const store = transaction.objectStore(storeName);
            const request = store.delete(id);

            request.onsuccess = (event) => {
                resolve({success: true})
            }

            request.onerror = (event) => {
                reject(event)
            }
        })
    }
}