
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    current(): string {
      var currDate = new Date();
      return currDate.getFullYear().toString();
    },
  },
  data: () => ({
    version: process.env?.VUE_APP_VERSION || "",
  })
});
