import PartsService from "@/services/PartsService";
import Part from "@/types/part";
import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "../../types/state";
import InventoryState from "../../types/state/inventory";
import InventoryService from "@/services/inventory";
import LocationService from "@/services/LocationService";
import { Location,InvLoc } from "@/types/state/inventory";
const namespaced = true;
const service = new InventoryService(process.env.VUE_APP_ABSTRACTION_API);
const PartService = new PartsService();
const locationService = new LocationService(process.env.VUE_APP_ABSTRACTION_API);
export const state: InventoryState = {
  part_no: "",
  average_cost: "",
  lot_no: "",
  group_avg_cost: "",
  change_date: "",
  change_time: "",
  changed_by: "",
  approved_by: "",
  change_text: "",
  change_notes: "",
  bin_items: [],
  it_date_items: [],
  cost_group_items: [],
  invloc_items: [],
  part_info: null,
  locations: null,
  um: ""
};

export const getters: GetterTree<InventoryState, RootState> = {
  getInventory(state): any {
    return state;
  },
  getPartLocation(state): Array<InvLoc> | null{
    return state.invloc_items;
  },
  getPart(state): Part | null {
    return state.part_info;
  },
  getLocations(state): Array<Location> | null {
    return state.locations;
  },
  getLocation(state) {
    return (location: string) => {
      const result = state.locations?.find((item) => {
        return item.invloc_id == location;
      });
      return result;
    };
  },
};

export const mutations: MutationTree<InventoryState> = {
  SET_INVENTORY(state, inventory) {
    state.part_no = inventory.part_no;
    state.average_cost = inventory.average_cost ? inventory.average_cost : null;
    state.lot_no = inventory.lot_no ? inventory.lot_no : null;
    state.group_avg_cost = inventory.group_avg_cost ? inventory.group_avg_cost : null;
    state.bin_items = inventory.bin_items ? inventory.bin_items : null;
    state.it_date_items = inventory.it_date_items ? inventory.it_date_items : null;
    state.cost_group_items = inventory.cost_group_items ? inventory.cost_group_items : null;
    state.invloc_items = inventory.invloc_items ? inventory.invloc_items : null;
    state.um = inventory.um ? inventory.um : null;
  },
  SET_PART(state, part) {
    state.part_info = part;
  },
  SET_LOCATIONS(state, locations) {
    locations.sort((a: Location, b: Location) => {
      return a.invloc_id.localeCompare(b.invloc_id);
    });
    state.locations = locations;
  },
};

export const actions: ActionTree<InventoryState, RootState> = {
  fetchInventory({ commit }, { id, client, correls }) {
    return new Promise((resolve, reject) => {
      service
        .getInventory(id, client, correls)
        .then((response: any) => {
          commit("SET_INVENTORY", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPart({ commit }, { id, client }) {
    return new Promise((resolve, reject) => {
      PartService.getPart(client, id)
        .then((response) => {
          commit("SET_PART", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchLocations({ commit }, { client }) {
    return new Promise((resolve, reject) => {
      locationService
        .getLocations(client)
        .then((response: any) => {
          commit("SET_LOCATIONS", response.invloc_items);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const inventory: Module<InventoryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
