import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";

export default class AddressValidation {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async validateCustomerAddress(payload: any) {
    const addressArray = [];
    addressArray.push(payload);
    return ClientAPI.Post(`/addresses/validate`, addressArray)
  }
}
