import { Module, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import NotificationState from "@/types/state/notification";
import { NotificationType } from "@/types/notification";

const namespaced = true;
let nextId = 1;

export const state: NotificationState = {
  notifications: [],
};

export const mutations: MutationTree<NotificationState> = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
    });
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationToRemove.id
    );
  },
};

export const actions: ActionTree<NotificationState, RootState> = {
  add({ commit }, notification) {
    commit("PUSH", notification);
  },
  addError({ commit }, message) {
    const notification = {
      message,
      type: NotificationType.ERROR,
    }
    commit("PUSH", notification);
  },
  addSuccess({ commit }, message) {
    const notification = {
      message,
      type: NotificationType.SUCCESS,
    }
    commit("PUSH", notification);
  },
  remove({ commit }, notificationToRemove) {
    commit("DELETE", notificationToRemove);
  },
};

export const notification: Module<NotificationState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};
