import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import FieldServiceControlState, { ReportTypeItem } from "@/types/controls/fieldService";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: FieldServiceControlState = {
  fso_oper_editable: "",
  fso_signature_required: "",
  operation_items: [],
  report_type_name_items: [],
  control_id: "",
  fso_hide_dates: "",
  fso_hide_reps: "",
};

export const getters: GetterTree<FieldServiceControlState, RootState> = {
  getOperations: (state) => {
    return state.operation_items;
  },
  allowEditOperations: (state) => {
    return state.fso_oper_editable === "Y";
  },
  showRepsSection: (state) => {
    return state.fso_hide_reps != "Y";
  },
  showDatesSection: (state) => {
    return state.fso_hide_dates != "Y";
  },
  signatureRequired: (state) => {
    return state.fso_signature_required === "Y";
  },
  getReportTypes: (state) => {
    return state.report_type_name_items;
  },
  reportName: (state) => (reportType: string) => {
    const report = state.report_type_name_items.find(
      (report: ReportTypeItem) => report.report_type_code === reportType
    );
    return report ? report.report_type_name : "";
  }
};

export const mutations: MutationTree<FieldServiceControlState> = {
  SET_FS_CONTROL(state, {fso_oper_editable, fso_signature_required, operation_items, report_type_name_items, control_id, fso_hide_dates, fso_hide_reps}) {
    state.fso_oper_editable = fso_oper_editable;
    state.operation_items = operation_items;
    state.report_type_name_items = report_type_name_items;
    state.control_id = control_id;
    state.fso_signature_required = fso_signature_required;
    state.fso_hide_dates = fso_hide_dates;
    state.fso_hide_reps = fso_hide_reps;
  },
};

export const actions: ActionTree<FieldServiceControlState, RootState> = {
  async getFieldServiceControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.control_id) {
        resolve(state);
      } else {
        controlService.getControl("FS1", "FS.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if(response.length > 0){
              const resp = response[0];
              commit("SET_FS_CONTROL", {
                fso_oper_editable: resp.fso_oper_editable || "",
                fso_signature_required: resp.fso_signature_required || "",
                operation_items: resp.operation_items || [],
                report_type_name_items: resp.report_type_name_items || [],
                control_id: resp.control_id || "FS1",
                fso_hide_dates: resp.fso_hide_dates || "",
                fso_hide_reps: resp.fso_hide_reps || "",
              });
              resolve(resp);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const fieldServiceControl: Module<FieldServiceControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
