import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b42d804"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid mr-0 mt-0 ml-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "p-4 border-1 border-400 border-round menu-item",
  style: {"height":"100%"}
}
const _hoisted_5 = { class: "flex flex-wrap align-items-center justify-content-between gap-2" }
const _hoisted_6 = { class: "flex align-items-center gap-2" }
const _hoisted_7 = {
  class: "font-semibold",
  style: {"padding":"15px 10px","font-size":"1.5rem","color":"white"}
}
const _hoisted_8 = { class: "col-12 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataView = _resolveComponent("DataView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NotificationCard = _resolveComponent("NotificationCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode(_component_DataView, {
          value: _ctx.visibleMenuItems,
          layout: "grid"
        }, {
          grid: _withCtx((slotProps) => [
            _createElementVNode("div", {
              class: "col-12 sm:col-6 lg:col-6 xl:col-12 p-1",
              onClick: ($event: any) => (_ctx.router(slotProps.data))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("i", {
                      class: _normalizeClass(slotProps.data.icon),
                      style: {"padding":"15px 10px","font-size":"1.5rem","color":"white"}
                    }, null, 2),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(slotProps.data.label), 1)
                  ])
                ])
              ])
            ], 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["value"])
      ], 512), [
        [_vShow, _ctx.isBarcodePath]
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_NotificationCard)
  ], 64))
}