
import ProgressSpinner from 'primevue/progressspinner';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from "vuex";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { cacheStat } from '@/types/state/pos';
import IonCloudDownloadOutline from '@/icons/IonCloudDownloadOutline.vue';
import IonCloudOfflineOutline from '@/icons/IonCloudOfflineOutline.vue';
import IonCloudDoneOutline from '@/icons/IonCloudDoneOutline.vue';

const cacheControlIds: {key: string, isCompanyBased?: boolean, procedure?: string}[] = [{key: "CAT"}, {key:"PRICE"}, {key:"MRK", isCompanyBased: true}, {key:"COMPANY", procedure: "CO"}, {key:"PSO"}]

export default defineComponent({
  components: {
    Button,
    ProgressSpinner,
    Dialog,
    DataTable,
    Column,
    IonCloudDownloadOutline,
    IonCloudOfflineOutline,
    IonCloudDoneOutline,
  },
  data() {
    return {
      isCaching: false,
      isOnline: true,
      showCachingDialog: false
    };
  },
  created() {
    this.isOnline = navigator.onLine;
    window.addEventListener("online", () => {
      this.isOnline = true;
    });
    window.addEventListener("offline", () => {
      this.isOnline = false;
    });
  },
  computed: {
    ...mapGetters({
      getCacheStats: "pos/getCacheStats",
      getCompanyCodes: "control/getCompanyCodes",
      getFilter: "session/getFilter",
    }),
    cacheStatistics(): any {
      return Object.entries(this.getCacheStats as {[key: string]: cacheStat}).map(([key, value]) => {
        return { key, ...value };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchControls: "control/fetchControl",
      addNotification: "notification/add",
      fetchCacheCustomers: "customer/fetchCacheCustomers",
      fetchCacheParts: "pos/fetchCacheParts",
      fetchCachePrices: "pos/fetchCachePrices",
      setCacheStats: "pos/setCacheStats",  
    }),
    cacheOfflineData() {
      this.isCaching = true;
      const cacheProcesses = [];
      const getCompanyCodes = this.getCompanyCodes;
      const reps = this.getFilter("CUST", "REP");
      this.addNotification({
          message: "Refreshing data, please wait...",
          type: "warn",
        });
      
      cacheProcesses.push(new Promise((resolve, reject) => {
         this.fetchCacheCustomers({rep: reps}).then((response) => {
            this.setCacheStats({
              key: "Customers",
              value: {cacheDateTime: Date.now(), cacheRecords: response.count},
            });
            resolve({success: true});
          }).catch((error) => {
            reject(error);
          });
        }));
      cacheProcesses.push(this.fetchCacheParts());
      cacheProcesses.push(this.fetchCachePrices({params: {reps: reps}}));
      cacheControlIds.forEach((controlItem) => {
        cacheProcesses.push(this.fetchControls({
          Client: "",
          id: controlItem.key,
          procedure: `${controlItem.procedure ? controlItem.procedure : controlItem.key}.CONTROL`,
          filename: "CONTROL",
        }).then((response) => {
          return new Promise((resolve,reject) => { 
            if (controlItem.key === "COMPANY") {
              cacheControlIds.filter((item) => item.isCompanyBased).forEach((companyBasedItem) => {
                if (getCompanyCodes.length == 0) {
                  resolve({success: true});
                } else {
                  getCompanyCodes.forEach((company: any) => {
                    this.fetchControls({
                      Client: "",
                      id: companyBasedItem.key + company.code,
                      procedure: `${companyBasedItem.key}.CONTROL`,
                      filename: "CONTROL",
                    }).then(() => {
                      resolve({success: true});
                    }).catch((error) => {
                      reject(error);
                    });
                });
              }
            });
            } else {
              resolve({success: true})
            }
          });
        }));
      });
      Promise.all(cacheProcesses).then(() => {
        this.addNotification({
          message: "Offline Data Cached",
          type: "success",
        });
      }).catch((error) => {
        console.log({...error})
        this.addNotification({
          message: "Error caching offline data",
          type: "error",
        });
      }).finally(() => {
        this.isCaching = false;
      });
    },
    openCachingDialog() {
      this.showCachingDialog = true;
    },
    closeCachingDialog() {
      this.showCachingDialog = false;
    }
  },
  
});
