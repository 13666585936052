import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class AllocationService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getAllocation(part: any, client: string, rangeStart?: number, rangeEnd?: number) {
    const params = {
      rangeStart,
      rangeEnd,
      part,
      client,
    };
    return ClientAPI.Get(`allocs`, params)
  }
}
