import { AxiosInstance } from "axios";
import axios from "axios";
import store from "@/store";
import qs from "qs";
import SaleOpp from "@/types/saleOpps";
import ClientAPI from "./ClientAPI";

export default class SaleOppsService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  getSaleOpps(ids: string, rangeStart: number, rangeEnd: number, dateStart: string, dateEnd: string, assignees: string, status: string, sortBy: string, sortOrder: string, custs: string, correls: string) {
    const params = {
      ids,
      rangeStart,
      rangeEnd,
      dateStart,
      dateEnd,
      assignee: assignees,
      status,
      sortBy,
      sortOrder,
      cust: custs,
      correls
    }
    return ClientAPI.Get(`sales/opportunities`, params)
  }

  postSaleOpp(payload: SaleOpp) {
    return ClientAPI.Post(`sales/opportunities`, payload)
  }

  updateSaleOpp(newSaleOpp: SaleOpp, oldSaleOpp: SaleOpp){
    return ClientAPI.Put(`sales/opportunities/${ newSaleOpp.id }`, { newSaleOpp, oldSaleOpp })
  }

}
