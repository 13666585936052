import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";

export default class WorkOrderService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getOrder(id: string, client: string) {
    const params = {
      client,
    };
    return ClientAPI.Get(`workorder/${id}`, params)
  }

  async getWorkOrderById(client: string, id: string, fieldnames: string, correls: string) {
    const params =  {
      client,
      fieldnames,
      correls
    };
    return ClientAPI.Get(`workorder/${id}`, params)
  }

  async getOrders(workorderRequest: {ScheduledWorkcenter: boolean, Status: string}) {
    const { ScheduledWorkcenter, Status } = workorderRequest
    const params =  {
      ScheduledWorkcenter: ScheduledWorkcenter,
      Status: Status
    };
    return ClientAPI.Get(`workorder`, params)
  }

  async putOrders(payload: any) {
    console.log(payload);
    const { records, oldRecords } = payload;
    return ClientAPI.Put(`workorder`, {records, oldRecords})
  }
}
