import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import ClientAPI from "./ClientAPI";

export default class RMAService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getRMAs(client: any, custId: string, ids: string) {
    const params = {
      ids,
      client,
      customer: custId,
    };
    return ClientAPI.Get(`rma`, params)
  }

}
