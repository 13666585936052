import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import { FieldServiceOrder, Operation } from "@/types/state/fieldServices";
import ClientAPI, { DEFAULT_CLIENT_FAILURE_HANDLER, DEFAULT_CLIENT_SUCCESS_HANDLER } from "./ClientAPI";

export default class FieldServiceOrderService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getFieldServices(
    client: any,
    ids: string,
    custs: string,
    fieldnames: string,
    correls: string,
    status: any,
    rangeStart: string,
    rangeEnd: string,
    dateStart: string,
    dateEnd: string,
    sortBy: string,
    sortOrder: string,
    reps: string,
    activeOnly: string,
  ) {
    return ClientAPI.Get(`fieldServices`, {
      Client: client,
      ids,
      custs,
      fieldnames,
      correls,
      status,
      rangeStart,
      rangeEnd,
      dateStart,
      dateEnd,
      sortBy,
      sortOrder,
      reps,
      activeOnly
    });
  }

  async getFieldServiceById(
    client: any,
    id: string,
    fieldnames: string,
    correls: string
  ) {
    return ClientAPI.Get(`fieldServices/${id}`, {
      Client: client,
      fieldnames,
      correls,
    });
  }

  async getFieldServicePDF(id: string, client: string, email?: any, reportType?: string) {
    const type = store.getters['fieldServiceControl/reportName'](reportType);

    let params: any = {
        client,
        reportType: type
    }

    if(email) {
      params = {
        client,
        reportType: type,
        email: email.To,
        subject: email.Subject,
        body: email.Body,
        from: email.From,
        cc: email.Cc,
        bcc: email.Bcc,
        html: email.Html
      }
    }

    return ClientAPI.Get(`fieldServices/${id}/pdf`, params)
  }

  async createFieldService(
    payload: FieldServiceOrder,
    successHandler?: any,
    failureHandler?: any,
  ) {
    payload.oper_li_items?.forEach((item: Operation) => {
      if(typeof(item.oper_notes) == "number") {
        item.oper_notes = item.oper_notes.toString();
      }
    });

    return ClientAPI.Post(`fieldServices`, payload, {}, successHandler || DEFAULT_CLIENT_SUCCESS_HANDLER, failureHandler || DEFAULT_CLIENT_FAILURE_HANDLER);
  }

  async updateFieldService(id: string, oldFieldServiceOrder: FieldServiceOrder, newFieldServiceOrder: FieldServiceOrder, Client: string, user?: string) {
    oldFieldServiceOrder.oper_li_items?.forEach((item: Operation) => {
      if(typeof(item.oper_notes) == "number") {
        item.oper_notes = item.oper_notes.toString();
      }
    });

    newFieldServiceOrder.oper_li_items?.forEach((item: Operation) => {
      if(typeof(item.oper_notes) == "number") {
        item.oper_notes = item.oper_notes.toString();
      }
    });

    const payload = {
      user,
      oldFieldServiceOrder,
      newFieldServiceOrder,
    };
    const params = {
      client: Client,
    };

    if (id == null) throw new Error("Field Service ID Missing");

    return ClientAPI.Put(`fieldServices/${id}`, payload, params);
  }

}
