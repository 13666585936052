import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import ClientAPI from "./ClientAPI";
export default class InformerService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false
        })
    }

    async getReportUrl(client: any) {
        return ClientAPI.Get(`dashboard`, { client: client })
        .catch(error => {
            store.dispatch('notification/add', {
                message: `Failed To Get Report URL: ${error?.response?.data?.error || error?.response?.data?.message || error?.error || error?.message || ''}`,
                type: "error",
            });
        });
    }

}