
import { defineComponent } from "vue";
import DataView from "primevue/dataview";
import NotificationCard from "@/components/Notification-Card.vue";
import { mapActions, mapGetters } from "vuex";
export default defineComponent({
  name: "BarcodeForm",
  components: {
    NotificationCard,
    DataView
  },
  computed: {
    ...mapGetters({
      getAccess: "session/getAccess",
    }),
    isBarcodePath(): boolean {
      return this.$route.path === "/scan" || false;
    },
    visibleMenuItems(): any[] {
      return this.menuItems.filter((item) => item.visible());
    },
  },
  methods: {
    ...mapActions({ changeSubTitle: "title/changeSubTitle",}),
    router(data: any) {
      this.$router.push(data.to);
      this.changeSubTitle(data.label);
    },
  },
  data() {
    return {
      menuItems: [
        {
          label: "Transfer",
          icon: "pi pi-arrows-h",
          to: "/scan/transfer/details",
          visible: () => this.getAccess("../scan/transfer/details"),
        },
        {
          label: "Inventory Check",
          icon: "pi pi-check",
          to: "/scan/inventory",
          visible: () => this.getAccess("../scan/inventory"),
        },
        {
          label: "Pick Shipment",
          icon: "pi pi-box",
          to: "/scan/pick",
          visible: () => this.getAccess("../scan/pick"),
        },
        {
          label: "Pack Shipment",
          icon: "pi pi-box",
          to: "/scan/pack",
          visible: () => this.getAccess("../scan/pack"),
        },
        {
          label: "Picking",
          icon: "pi pi-envelope",
          to: "/scan/picking",
          visible: () => this.getAccess("../scan/picking"),
        },
        {
          label: "Parts Checkout",
          icon: "pi pi-arrows-h",
          to: "/scan/order",
          visible: () => this.getAccess("../scan/order/details"),
        },
        {
          label: "Start Job",
          icon: "pi pi-clock",
          to: "/scan/startJob",
          visible: () => this.getAccess("../scan/startJob"),
        },
        {
          label: "End Job",
          icon: "pi pi-clock",
          to: "/scan/endJob",
          visible: () => this.getAccess("../scan/endJob"),
        },
        {
          label: "Shop Transaction",
          icon: "pi pi-box",
          to: "/scan/shopTransaction",
          visible: () => this.getAccess("../scan/shopTransaction"),
        },
        {
          label: "Scan Shipment",
          icon: "pi pi-box",
          to: "/scan/shipment",
          visible: () => this.getAccess("../scan/shipment"),
        },
        {
          label: "Physical Count",
          icon: "pi pi-database",
          to: "/scan/count",
          visible: () => this.getAccess("../scan/count"),
        },
        {
          label: "Physical Recount",
          icon: "pi pi-sync",
          to: "/scan/recount",
          visible: () => this.getAccess("../scan/recount"),
        },
      ],
    };
  },
});
