import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import AccountsReceivableState from "@/types/state/accountsReceivable";

import ARService from "@/services/ARService";
import { ADD_ERROR_NOTIFICATION } from "@/types/notification";

const arService = new ARService();

const namespaced = true;

const CREDIT_MEMO_AR_TYPE = "CM"

export const state: AccountsReceivableState = {
  arItems: null,
  page: 1,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
};

export const getters: GetterTree<AccountsReceivableState, RootState> = {
  getAccountReceivable: (state) => {
    return state.arItems;
  },
  getRangeStart: (state) => {
    return state.rangeStart;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getPage: (state) => {
    return state.page;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
};

export const mutations: MutationTree<AccountsReceivableState> = {
  SET_AR_ITEMS(state, data) {
    if (state.arItems) {
      state.arItems = [...state.arItems, ...data];
    } else {
      state.arItems = [...data];
    }
  },
  SET_NEXT_PAGE(state, page) {
    state.page = page;
  },
  SET_NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  CLEAR_ACCOUNTS_RECEIVABLE(state) {
    state.arItems = [];
  },
};

export const actions: ActionTree<AccountsReceivableState, RootState> = {
  async fetchArRecords({ state, commit, dispatch },{cust, dueDateStart, dueDateEnd, sortBy, sortOrder,addAccountsReceivable }) {
    if (!addAccountsReceivable) {
      commit("RESET_PAGE");
    }
    await arService
      .getARRecords(
        cust,
        state.rangeStart,
        state.rangeEnd,
        dueDateStart,
        dueDateEnd,
        sortBy,
        sortOrder
      )
      .then((response: any) => {
        if (!addAccountsReceivable) {
          commit("CLEAR_ACCOUNTS_RECEIVABLE");
          commit("SET_FIRST_ROW", 0);
        }
        commit("SET_AR_ITEMS", response.ar_items);
        commit("SET_NEXT_RANGE");
      })
      .catch((error) => {
        dispatch(
          "notification/add",
          {
            message: `Error Displaying Accounts Receivable Records. ${error.error || error.message}.`,
            type: "error",
          },
          { root: true }
        );
      });
      
  },
  setFirstRow({ commit }, row) {
    commit("SET_FIRST_ROW", row);
  },
  async postARRefund({ dispatch }, order) {
    order.type = CREDIT_MEMO_AR_TYPE
    
    try {
      const resp = await arService.postInvoice(order);
      return resp;
    } catch (error: any) {
      dispatch(
        ADD_ERROR_NOTIFICATION,
        {
          message: `Error Posting Refund. ${error.error || error.message}.`,
        },
        { root: true}
      );
    }
  }
};

export const accountsReceivable: Module<AccountsReceivableState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
