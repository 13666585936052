import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import { Response } from "@/types/accountsPayable";
import AccountsPayableState from "@/types/state/accountsPayable";

import AccountsPayableService from "@/services/AccountsPayable";


const namespaced = true;

const accountsPayableService = new AccountsPayableService()

export const state: AccountsPayableState = {
    accountsPayable: null,
    page: 1,
    firstRow: 0,
    rangeStart: 1,
    rangeEnd: 100,
}

export const getters: GetterTree<AccountsPayableState, RootState> = {
    getAccountsPayable: (state) => {
        return state.accountsPayable
    },
    getRangeStart: (state) => {
        return state.rangeStart;
    },
    getRangeEnd: (state) => {
        return state.rangeEnd;
    },
    getFirstRow: (state) => {
        return state.firstRow;
    },
    getCurrPage: (state) => {
        return state.page;
    }
}

export const mutations: MutationTree<AccountsPayableState> = {
    SET_ACCOUNTS_PAYABLE(state, data){
        if(state.accountsPayable != null){
            state.accountsPayable!.push(...data)
        } else {
            state.accountsPayable = data
        }
    },
    SET_FIRST_ROW(state, row) {
        state.firstRow = row
    },
    SET_NEXT_PAGE(state, page) {
        state.page = page
    },
    SET_NEXT_RANGE(state){
        state.rangeStart += 100;
        state.rangeEnd += 100;
    },
    RESET_PAGE(state) {
        state.page = 1;
        state.rangeStart = 1
        state.rangeEnd = 100
    },
    CLEAR_ACCOUNTS_PAYABLE(state) {
        state.accountsPayable = null;
    }
}

export const actions: ActionTree<AccountsPayableState, RootState> = {
    fetchAccountsPayable({ commit, dispatch }, {id, po, status, addAccountsPayable, vendorId, dueDateStart, dueDateEnd, sortBy, sortOrder }) {
    
        return new Promise((resolve, reject) => {
            if(!addAccountsPayable){
                commit("RESET_PAGE");
            }
            accountsPayableService.getAccountsPayables(id, po, status, state.rangeStart, state.rangeEnd, vendorId, dueDateStart, dueDateEnd, sortBy, sortOrder)
            .then((response) => {
                if(!addAccountsPayable){
                    commit("CLEAR_ACCOUNTS_PAYABLE");
                    commit("SET_FIRST_ROW", 0);
                }
                commit("SET_ACCOUNTS_PAYABLE", (response as Response).ap_items)
                commit("SET_NEXT_RANGE")
                resolve({ success: true })
            }).catch((error) => {
                reject({ success: false })
            });
        })
    },

    setFirstRow({ commit }, row) {
        commit("SET_FIRST_ROW", row)
    }

}

export const accountsPayable: Module<AccountsPayableState, RootState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}