import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import { FdictState, Fdict, Field } from "@/types/fdict";
import FileDictionary from "@/services/FileDictionaryService";
import store from "..";
import Utils from "@/utility/utils";
import { control } from "../ui/control";

const namespaced = true;
const FdictService = new FileDictionary(process.env.VUE_APP_ABSTRACTION_API);

export const state: FdictState = {
  fdicts: [],
};

export const getters: GetterTree<FdictState, RootState> = {
  getFdicts: (state) => {
    return state.fdicts;
  },
  getFdict: (state) => (id: string) => {
    return state.fdicts.find((fdict) => fdict.file_name === id);
  },
  getFdictFields: (state) => (id: string, control_field = null): Field[] => {
    const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
    if (fdict) {
      const controlFields = new Set(fdict.field_no_items.map((item) => item.control_field).filter((value) => value));
      const parentFields = [...controlFields]
      return fdict.field_no_items.filter((field) => field.control_field == control_field && !parentFields.includes(field.field_no));
    } else {
      return [];
    }
  },
  customFields: (state) => (id: string, control_field = null): Field[] => {
    const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
    if (fdict) {
      return fdict.field_no_items.filter((field) => ((+field.field_no >= 80 && +field.field_no <= 100) || field.web_required === "Y" || field.custom_field === "Y") && (field.control_field == control_field));
    } else {
      return [];
    }
  },
  fieldExists: (state) => (id: string, field_name: string): boolean => {
    const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
    if (fdict) {
      return fdict.field_no_items.some((item) => item.dict_name === field_name);
    } else {
      return false;
    }
  },
  getField: (state) => (id: string, field_no: string): Field => {
    const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
    if (fdict) {
      const field = fdict.field_no_items.find((item) => item.field_no === field_no);
      if (field) {
        return field;
      } else {
        return {} as Field;
      }
    } else {
      return {} as Field;
    }
  },
  getFieldLabel: (state) => (id: string, field_no: string, defaultLabel = ""
  ): string => {
    const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
    if (fdict) {
      const field = fdict.field_no_items.find((item) => item.field_no === field_no);
      if (field && field.display_name) {
        return field.display_name;
      } else if (field && field.custom_label) {
        // This is deprecated, but may still be used in some places. Keeping for backward compatibility until integrations can make the move
        return field.custom_label;
      } else {
        return defaultLabel;
      }
    } else {
      return defaultLabel;
    }
  },
  controlFieldValues: (state) => (id: string, control_field: string): Field[] => {
    const fdict = state.fdicts.find((fdict) => fdict.file_name === id);
    if (fdict) {
      const controlFields = fdict.field_no_items.filter((item) => item.control_field === control_field);
      return controlFields.map((item) => {
        return {
          ...item,
          label: item.desc_items.map((desc) => desc.desc).join(" "),
          json_name: item.dict_name.toLowerCase().replaceAll(".", "_"),
        }
      })
    } else {
      return [] as Field[];
    }
  },
};

export const mutations: MutationTree<FdictState> = {
  ADD_FDICT(state, fdict: Fdict) {
    if (!fdict) return;

    fdict.field_no_items.forEach((field) => {
      if(!field.json_name) {
        field.json_name = field.dict_name.toLowerCase().replaceAll(".", "_");
      }
      if(!field.custom_field) {
        if(+field.field_no >= 80 && +field.field_no <= 100){
          field.custom_field = "Y";
        } else {
          field.custom_field = ""
        }
      }
      if(!field.display_name && field.custom_field) {
        field.display_name = Utils.formatDictionaryName(field.dict_name);
      }
    });

    const index = state.fdicts.findIndex((item) => item.file_name === fdict.file_name);
    if (index > -1) {
      state.fdicts[index] = fdict;
    } else {
      state.fdicts = [...state.fdicts, fdict];
    }
  },
};

export const actions: ActionTree<FdictState, RootState> = {
  fetchFdict({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      let fdict = null;
      if(state.fdicts){
        fdict = state.fdicts.find((fdict) => fdict.file_name === id);
      }
      if (fdict) {
        resolve(fdict);
      } else {
        const client = store.getters["session/getClient"];
        FdictService.getFdict(client, id)
          .then((response: any) => {
            if(response as Fdict && response.fdict_items){
              commit("ADD_FDICT", response.fdict_items[0]);
              resolve(response.fdict_items[0]);
            } else {
              reject("No data returned for FDICT " + id);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const fdict: Module<FdictState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
