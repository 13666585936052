import ApiService from "./ApiService";
import ClientAPI from "./ClientAPI";

export default class ControlService extends ApiService {
  private cache = true;
  
  constructor() {
    super("control", [
      { name: "control", keyPath: "control_id" },
    ]);
  }

  async getControl(
    id: string,
    procedure: string,
    filename: string,
    fieldnames?: string,
    Client?: string
  ) {

    if (fieldnames && fieldnames != "*" && !fieldnames.includes("control_id")) {
      fieldnames = "control_id " + fieldnames;
    }
    
    const params = {
      client: Client,
      id,
      procedure,
      filename,
      fieldnames,
    };

    if (this.isOffline) {
      return new Promise((resolve, reject) => {
        this.dbStore?.getRecord("control", id)
          .then((results) => {
            resolve([results]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`control`, params)
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecord("control", (results as any[])[0]);
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getPaymentInfo(Client: string, option: string) {
    const params = {
      client: Client,
      option,
    };
    return ClientAPI.Get(`payment/info`, params)
  }
}
