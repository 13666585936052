import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import GeneralLedgerState from "@/types/state/generalLedger";
import GlChartService from "@/services/GlChartsService";

const glService = new GlChartService();

const namespaced = true;

export const state: GeneralLedgerState = {
  trialBalance: {},
  
};

export const getters: GetterTree<GeneralLedgerState, RootState> = {
  getTrialBalance: (state) => {
    return state.trialBalance;
  },
};

export const mutations: MutationTree<GeneralLedgerState> = {
  SET_TRIAL_BALANCE(state, data) {
    state.trialBalance = data;
  },
};

export const actions: ActionTree<GeneralLedgerState, RootState> = {
  fetchTrialBalance({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      glService
        .getTrialBalance()
        .then((response: any) => {
          commit("SET_TRIAL_BALANCE", response);
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false });
        });
    });
  },
  fetchAccountActivity({ commit, dispatch }, accountActivityRequest) {
    return new Promise((resolve, reject) => {
      glService
        .getAccountActivity(accountActivityRequest)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false });
        });
    });
  },
  fetchMonthEndProgressKpi() {
    return new Promise((resolve, reject) => {
      glService
        .getMonthEndProgressKpi()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject({ success: false, error });
        });
    });
  
  },
  postInterfaceRegister({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      glService
        .postInterfaceRegister(payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false });
        });
    });
  },
  glPost({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      glService
        .glPost(payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false });
        });
    });
  },
  getRegister({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      glService
        .getRegisters(payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false });
        });
    });

  },
};

export const generalLedger: Module<GeneralLedgerState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
