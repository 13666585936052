import FieldServicesState, {
  FieldServiceObject,
  FieldServiceOrder,
} from "@/types/state/fieldServices";
import { RootState } from "@/types/state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import FieldServiceOrderService from "@/services/FieldServiceOrderService";
import Utils from "@/utility/utils";
import Customer from "@/types/customer";

const namespaced = true;

export const state: FieldServicesState = {
  FieldServiceMap: [],
  activeTab: 0,
  pdfs: [],
  loadingPDFs: [],
  lastFieldServiceOrder: {} as FieldServiceObject,
};

const fieldService = new FieldServiceOrderService(
  process.env.VUE_APP_ABSTRACTION_API
);

export const getters: GetterTree<FieldServicesState, RootState> = {
  getOrderMap(state) {
    return state.FieldServiceMap;
  },
  getFieldServices(state) {
    return state.FieldServiceMap;
  },
  getActiveFieldService(state) {
    if (state.activeTab - 1 in state.FieldServiceMap) {
      return state.FieldServiceMap[state.activeTab - 1];
    } else {
      return null;
    }
  },
  getActiveInnerTab: (state) => (orderId: string) => {
    const index = state.FieldServiceMap.findIndex(
      (order) => order.old_record.order_no === orderId
    );
    if (index > -1) {
      return state.FieldServiceMap[index].activeInnerTab;
    }
    return 0;
  },
  getActiveInnerTabSuffix: (state, getters) => (orderId: string) => {
    const index = state.FieldServiceMap.findIndex(
      (order) => order.old_record.order_no === orderId
    );
    if (index > -1) {
      const activeInnerTab = state.FieldServiceMap[index].activeInnerTab;
      return getters.getInnerTabs[activeInnerTab].suffix;
    }
    return "";
  },
  getActiveTab(state) {
    return state.activeTab;
  },
  getInnerTabs() {
    return [
      {
        label: "Field Services",
        prefix: "/fieldservices",
        suffix: "fieldservice",
      },
    ];
  },
  getPDFs: (state) => {
    return state.pdfs;
  },
  getLoadingPDFs: (state) => {
    return state.loadingPDFs || [];
  },
  getLastFieldServiceOrder: (state) => {
    return state.lastFieldServiceOrder;
  },
};

export const mutations: MutationTree<FieldServicesState> = {
  PUSH_FIELD_SERVICE_MAP(state, order: FieldServiceOrder) {
    const fieldServiceObject: FieldServiceObject = {
      old_record: JSON.parse(JSON.stringify(order)),
      record: order,
      activeInnerTab: 0,
      customer: {} as Customer,
    };
    state.FieldServiceMap = [fieldServiceObject, ...state.FieldServiceMap];
  },
  REMOVE_FIELD_SERVICE_MAP(state, fieldServiceObject: FieldServiceObject) {
    state.FieldServiceMap = state.FieldServiceMap.filter(
      (item) =>
        item.old_record.order_no !== fieldServiceObject.old_record.order_no
    );
  },
  UPDATE_ACTIVE_TAB(state, tabIndex: number) {
    state.activeTab = tabIndex;
  },
  UPDATE_ACTIVE_INNER_TAB(state, payload) {
    const index = state.FieldServiceMap.findIndex(
      (order: any) => order.order_no === payload.orderId
    );
    if (index > -1) {
      state.FieldServiceMap[index].activeInnerTab = payload.tabIndex;
    }
  },
  ADD_PDF(state, pdf) {
    // Try to remove before adding
    state.pdfs = state.pdfs.filter((item) => item.id !== pdf.id);
    state.pdfs.push(pdf);
  },
  REMOVE_PDF_BY_ID(state, id) {
    state.pdfs = state.pdfs.filter((item) => item.id !== id);
  },
  ADD_PDF_LOADING(state, id) {
    state.loadingPDFs = state.loadingPDFs.filter((item) => item !== id);
    state.loadingPDFs.push(id);
  },
  REMOVE_PDF_LOADING(state, id) {
    state.loadingPDFs = state.loadingPDFs.filter((item) => item !== id);
  },
  SET_LAST_FIELD_SERVICE_ORDER(state, payload) {
    state.lastFieldServiceOrder = payload;
  },
};

export const actions: ActionTree<FieldServicesState, RootState> = {
  addNewFieldService({ commit, state }, data) {
    if (typeof data === "string" && data.startsWith("New")) {
      const newOrder: FieldServiceOrder = {} as any;
      newOrder.order_no = data;
      commit("PUSH_FIELD_SERVICE_MAP", newOrder);
      commit("UPDATE_ACTIVE_TAB", 1);
    } else {
      const isOpened = state.FieldServiceMap.filter(
        (item) => item.old_record.order_no === data.order_no
      );
      if (isOpened.length === 0) {
        commit("PUSH_FIELD_SERVICE_MAP", data);
        commit("UPDATE_ACTIVE_TAB", 1);
      } else {
        commit(
          "UPDATE_ACTIVE_TAB",
          state.FieldServiceMap.indexOf(isOpened[0]) + 1
        );

        // Record was not getting updated if it was open and a user clicked on the table row.
        isOpened[0].record = data;
        isOpened[0].old_record = JSON.parse(JSON.stringify(data));
      }
    }
  },
  removeOpenedFieldServiceOrder({ commit, state }, payload) {
    commit("REMOVE_FIELD_SERVICE_MAP", payload);
    if (state.activeTab > 0) {
      commit("UPDATE_ACTIVE_TAB", 0);
    }
  },
  removeOpenedLineItem({ commit, state }, payload) {
    commit("REMOVE_LINE_ITEM_MAP", { ...payload });
  },
  changeActiveTab({ commit }, tabIndex: number) {
    commit("UPDATE_ACTIVE_TAB", tabIndex);
  },
  changeLineItemTab({ commit }, payload) {
    commit("UPDATE_LINE_ITEM_TAB", payload);
  },
  changeActiveInnerTab({ commit }, payload) {
    commit("UPDATE_ACTIVE_INNER_TAB", payload);
  },
  findOpenedOrder({ state }, id) {
    const openedOrder = state.FieldServiceMap.filter(
      (item) => item.old_record.order_no === id
    );
    if (openedOrder) {
      return openedOrder[0];
    }
    return {};
  },
  setLastFieldServiceOrder({ commit }, payload) {
    commit("SET_LAST_FIELD_SERVICE_ORDER", payload);
  },
  async getFieldServicePDF({ commit, dispatch, state }, payload) {
    const pdf = state.pdfs.find((i) => i.id === payload.recordId);
    let isFile = null;
    if (pdf?.pdf) {
      isFile = window.open(pdf.pdf, "_blank");
    }

    if (isFile === null) {
      commit("ADD_PDF_LOADING", payload.recordId);
      await fieldService
        .getFieldServicePDF(payload.recordId, payload.Client, "", payload.reportType)
        .then((resp: any) => {
          const bufferArray = Utils.base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
          commit("ADD_PDF", { id: payload.recordId, pdf: data });
          commit("REMOVE_PDF_LOADING", payload.recordId);
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message: error,
          };
          dispatch("notification/add", notification, { root: true });
          commit("REMOVE_PDF_LOADING", payload.recordId);
        });
    }
  },
};

export const fieldServices: Module<FieldServicesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
