import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";

import pako from "pako";
import AttachmentsState from "@/types/state/attachments";

const namespaced = true;

import AttachmentService from "@/services/AttachmentService";

const attachmentService = new AttachmentService(
  process.env.VUE_APP_ABSTRACTION_API
);

export const state: AttachmentsState = {
  AttachmentsMap: [],
};

export const getters: GetterTree<AttachmentsState, RootState> = {
  getCustomerAttachments: (state) => (index: number) => {
    return state.AttachmentsMap[index]?.customers?.attachments || null;
  },
  getCustomerAttachmentPageIndex: (state) => (index: number) => {
    return state.AttachmentsMap[index]?.customers?.page;
  },
  getCustomerAttachmentFirstRow: (state) => (index: number) => {
    return state.AttachmentsMap[index].customers.row;
  },
  getCustomerAttachmentRangeEnd: (state) => (index: number) => {
    return state.AttachmentsMap[index]?.customers?.rangeEnd;
  },
};

export const mutations: MutationTree<AttachmentsState> = {
  INIT_STATE(state) {
    const attachmentsState = {
      customers: {
        attachments: [],
        rangeStart: 1,
        rangeEnd: 5,
        row: 0,
        page: 0,
        attachmentsPerPage: 5,
      },
      tickets: {
        attachments: [],
        rangeStart: 1,
        rangeEnd: 100,
        row: 1,
        page: 0,
        ticketsPerPage: 5,
      },
    };

    state.AttachmentsMap = [attachmentsState, ...state.AttachmentsMap];
  },
  REMOVE_ATTACHMENT(state, index) {
    state.AttachmentsMap.splice(index, 1);
  },
  SET_CUST_ATTACHMENTS(state, { index, data }) {
    state.AttachmentsMap[index].customers.attachments = data;
  },
  ADD_NEW_CUST_ATTACHMENT(state, { attachment, index }) {
    let attachmentCopy = state.AttachmentsMap[index].customers.attachments;
    if(attachmentCopy) {
      attachmentCopy = [attachment, ...attachmentCopy];
    } else {
      attachmentCopy = [attachment];
    }
    state.AttachmentsMap[index].customers.attachments = attachmentCopy;
  },
  DELETE_CUST_ATTACHMENT(state, { attachment_id, index }) {
    if (state.AttachmentsMap.length != 0) {
      if (index > -1) {
        const objIndex = state.AttachmentsMap[
          index
        ].customers?.attachments?.findIndex(
          (attachment) => attachment.id === attachment_id
        );
        if (objIndex != null && objIndex != -1) {
          state.AttachmentsMap[index].customers.attachments.splice(objIndex, 1);
        }
      }
    }
  },
  CLEAR_CUST_ATTACHMENTS(state, { index }) {
    state.AttachmentsMap[index].customers.attachments = [];
  },
  SET_CUSTOMER_NEXT_RANGE(state, { index }) {
    state.AttachmentsMap[index].customers.rangeStart += 100;
    state.AttachmentsMap[index].customers.rangeEnd += 100;
  },
  RESET_CUST_ROW(state, { index }) {
    state.AttachmentsMap[index || 0].customers.row = 0;
    state.AttachmentsMap[index || 0].customers.rangeStart = 1;
    state.AttachmentsMap[index || 0].customers.rangeEnd = 100;
  },
  SET_CUST_NEXT_PAGE(state, { index, pageNumber }) {
    state.AttachmentsMap[index].customers.page = pageNumber;
  },
  SET_CUST_ROW_INDEX(state, { row, index }) {
    state.AttachmentsMap[index].customers.row = row;
  },
};

export const actions: ActionTree<AttachmentsState, RootState> = {
  fetchAttachments({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const { record, isAddingAttachments, index } = payload;

      let ids = "";
      const attachmentsRecords = (record as any)?.attachments_items != null
          ? [...(record as any)?.attachments_items]
          : null;

      if (attachmentsRecords != null) {
        attachmentsRecords?.forEach((element) => {
          ids += element.attachments + " ";
        });

        if (!isAddingAttachments && index != null)
          commit("RESET_CUST_ROW", { index });
        attachmentService.getAttachments(
          ids,
          state.AttachmentsMap[index]?.customers.rangeStart || 1,
          state.AttachmentsMap[index]?.customers.rangeEnd || 100
        )
        .then((response) => {
          if (!isAddingAttachments && index != null) {
            commit("CLEAR_CUST_ATTACHMENTS", { index });
          }
          if (index != null) {
            commit("SET_CUST_ATTACHMENTS", {
              data: (response as any).attachment_items || [],
              index,
            });
            commit("SET_CUSTOMER_NEXT_RANGE", { index });
          }
          resolve({
            success: true,
            attachments: (response as any).attachment_items || [],
          });
        })
        .catch((error) => {
          reject({ success: false, error });
        });
      } else {
        resolve({ success: true });
      }
    });
  },
  postAttachment({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const zipped: Uint8Array = pako.gzip(payload.doc);
      const len = zipped.byteLength;
      let zippedString = "";
      for (let i = 0; i < len; i++) {
        zippedString += String.fromCharCode(zipped[i]);
      }
      const b64 = btoa(zippedString);
      payload.doc = b64;

      attachmentService
        .addAttachment(payload)
        .then((response: any) => {
          if (response.status === "success") {
            if (payload.host_file === "CUST" && payload.index != null) {
                commit("ADD_NEW_CUST_ATTACHMENT", {
                  attachment: {
                    id: response.attachment.id,
                    date_created: response.attachment.date_created,
                    time_created: response.attachment.time_created,
                    file_name: payload.doc_name,
                    created_by: payload.user_id,
                  },
                  desc: payload.desc,
                  index: payload.index,
                });
                // UPDATE CUSTOMER OBJECT WITH NEW ATTACHMENT ID
                dispatch(
                  "customerInquiry/addAttachment",
                  {
                    attachmentId: response.attachment.id,
                    description: payload.desc,
                    index: payload.index,
                  },
                  { root: true }
                );
            }
            resolve({
              success: response.status === "success",
              attachment_id: response.attachment.id,
            });
          } else {
            reject({
              success: response.status === "success",
              error: response.error,
            });
          }
        })
        .catch((error) => {
          reject({ success: false, error });
        });
    });
  },
  deleteAttachment({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      attachmentService
        .deleteAttachment(payload)
        .then((response: any) => {
          if (response.status === "success") {
            commit("DELETE_CUST_ATTACHMENT", {
              attachment_id: payload.id,
              index: payload.index,
            });
            // UPDATE CUSTOMER OBJECT
            dispatch(
              "customerInquiry/deleteAttachment",
              { attachment_id: payload.id, index: payload.index },
              { root: true }
            );
            resolve({ success: true });
          } else {
            reject({ success: false, error: response.error });
          }
        })
        .catch((error) => {
          reject({ success: false, error });
        });
    });
  },
  downloadAttachment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;

      attachmentService
        .downloadAttachment(id)
        .then((response) => {
          if (!(response as any).error) {
            const decoded = atob((response as any).document);
            const len = decoded.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = decoded.charCodeAt(i);
            }
            const unzipped = pako.ungzip(bytes);
            const blobStore = new Blob([unzipped], { type: payload.mime_type });
            const data = URL.createObjectURL(blobStore);
            const link = document.createElement("a");
            link.href = data;
            link.download = (response as any).fileName;
            link.click();
            resolve({ success: true });
          } else {
            reject({ success: false, error: (response as any).error });
          }
        })
        .catch((error) => {
          reject({ success: false, error });
        });
    });
  },
  removeAttachmentStateObject({ commit }, index) {
    commit("REMOVE_ATTACHMENT", index);
  },
  addAttachmentState({ commit }) {
    commit("INIT_STATE");
  },
  updatePage({ commit }, { pageNumber, index }) {
    commit("SET_CUST_NEXT_PAGE", { pageNumber, index });
  },
  updateCustomerRowIndex({ commit }, { row, index }) {
    commit("SET_CUST_ROW_INDEX", { row, index });
  },
};

export const attachments: Module<AttachmentsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
