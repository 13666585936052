import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import TermsState from "@/types/state/terms";
import TermsService from "@/services/TermsService";

const namespaced = true;
const termsService = new TermsService(process.env.VUE_APP_ABSTRACTION_API);

export const state: TermsState = {
  terms_items: [],
};


export const getters: GetterTree<TermsState, RootState> = {
  getTerms: (state) => {
    return state.terms_items;
  },
};

export const mutations: MutationTree<TermsState> = {
  SET_DATA(state, terms) {
    state.terms_items = terms;
  },
};

export const actions: ActionTree<TermsState, RootState> = {
  async fetchTerms({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.terms_items.length > 0) {
        resolve(state);
      } else {
        termsService.getTerms()
          .then((response: any) => {
            if(response.length > 0) {
              commit("SET_DATA", response);
              resolve(response);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const terms: Module<TermsState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
