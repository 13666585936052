import axios from "axios";
import store from "../store";
import qs from "qs";
import Utils from "@/utility/utils";

const LOCAL_API_URL = process.env.VUE_APP_DESKTOP_SERVICE_API;

export default class LocalDesktopAPI {
  static client = axios.create({
    baseURL: LOCAL_API_URL,
    withCredentials: false,
  });

  public static async Get(
    endpoint: string,
    params: any,
    supressErrorNotifications = false,
  ) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then((resp) => {
          const message =
            Utils.parseSuccessMessage(resp);
          if (message) {
            store.dispatch("notification/add", {
              message,
              type: "success",
            });
          }
          resolve(resp.data);
        })
        .catch((error) => {
          const message = Utils.parseErrorMessage(error);
          if (message && !supressErrorNotifications) {
            // Display specific notification for Network Error
            if(message === "Network Error") {
              store.dispatch("notification/add", {
                message: "Please check the Rover Web Local Service status.",
                type: "error",
              });
            }
            else{
              store.dispatch("notification/add", {
                message,
                type: "error",
              });
            }
            
          }
          reject(error);
        });
    });
  }

  public static async Put(
    endpoint: string,
    payload: any,
    params?: any,
  ) {
    return new Promise((resolve, reject) => {
      this.client
        .put(endpoint, payload, {
          params,          
        })
        .then((resp) => {
          const message =
            Utils.parseSuccessMessage(resp);
          if (message) {
            store.dispatch("notification/add", {
              message,
              type: "success",
            });
          }
          resolve(resp.data);
        })
        .catch((error: any) => {
          const message = Utils.parseErrorMessage(error);
          if (message) {
            store.dispatch("notification/add", {
              message,
              type: "error",
            });
          }
          reject(error);
        });
    });
  }

  public static async Post(
    endpoint: string,
    payload: any,
    params?: any,
  ) {
    return new Promise((resolve, reject) => {
      this.client
        .post(endpoint, payload, {
          params,
        })
        .then((resp) => {
          const message =
            Utils.parseSuccessMessage(resp);
          if (message) {
            store.dispatch("notification/add", {
              message,
              type: "success",
            });
          }
          resolve(resp.data);
        })
        .catch((error) => {
          const responseData = error.response ? error.response.data : null;
          const message = Utils.parseErrorMessage(error);
          if(responseData.error){
            store.dispatch("notification/add", {
              message: responseData.message,
              type: "error",
            });
          }
          else {
            if (message) {
              store.dispatch("notification/add", {
              message,
              type: "error",
            });
            }
          }
          reject(error);
        });
    });
  }
}