import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";

export default class BillingService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }
  async getbills(ticket: string){
    const params = {
      ticket
    }
    return ClientAPI.Get(`bills`, params)
  }

  async getbill(ticket: string){
    const params = {
      ticket
    }
    return ClientAPI.Get(`bill`, params)
  }

  async postBillingInfo(payload: any) {
    return ClientAPI.Post(`bill`, {bill:{...payload}})
  }
}
