import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import PaymentState from "@/types/state/payment";
import PaymentService from "@/services/PaymentService";
import ControlService from "@/services/ControlService";
const namespaced = true;
const service = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const controlService = new ControlService();

export const state: PaymentState = {
  loading: false,
  success: false,
  recordId: "",
  error: "",
  iframeurl: "",
  achAllowed: false,
  ccAdrRqrd: false,
};

export const getters: GetterTree<PaymentState, RootState> = {
  getIframeUrl: (state) => {
    return state.iframeurl;
  },
  getAchAllowed: (state) => {
    return state.achAllowed;
  },
  getCcAdrRqrd: (state) => {
    return state.ccAdrRqrd;
  },
  getLoading: (state) => {
    return state.loading;
  },
  getSuccess: (state) => {
    return state.success;
  },
  getRecordId: (state) => {
    return state.recordId;
  },
  getError: (state) => {
    return state.error;
  },
};

export const mutations: MutationTree<PaymentState> = {
  TOGGLE_LOADING(state, loading) {
    state.loading = loading;
  },
  TOGGLE_SUCCESS(state, success) {
    state.success = success;
  },
  SET_RECORDID(state, recordId) {
    state.recordId = recordId;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  CLEAR_STATE(state) {
    state.error = "";
    state.loading = false;
    state.success = false;
  },
  SET_IFRAME_URL(state, { url }) {
    state.iframeurl = url;
  },
  SET_ACH_ALLOWED(state, flag) {
    state.achAllowed = flag === "Y" || flag === "y" ? true : false;
  },
  SET_CC_ADR_RQRD(state, flag) {
    state.ccAdrRqrd = flag === "Y" || flag === "y" ? true : false;
  },
};

export const actions: ActionTree<PaymentState, RootState> = {
  async postACH({ commit, dispatch, state }, payload: any) {
    commit("TOGGLE_LOADING", true);
    let serviceresponse = null;
    await service
      .PayACH(
        payload.amount,
        payload.account,
        payload.route,
        payload.type,
        payload.cust,
        payload.invoices,
        payload.Client,
        payload.waive_conv_fee,
        payload.data_source,
        payload.receipt_email_address,
        payload.meta
      )
      .then((response: any) => {
        if (response) {
          commit("TOGGLE_SUCCESS", true);
          commit("SET_RECORDID", response.recordId);
          serviceresponse = response;
          const notification = {
            type: "success",
            message: `Payment Submitted Successfully! Confirmation number: ${response.recordId}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          const notification = {
            type: "error",
            message: "Invalid Invoice Option",
          };
          commit("TOGGLE_SUCCESS", false);
        }
      })
      .catch((error: any) => {
        commit("TOGGLE_SUCCESS", false);
        commit("SET_ERROR", error.error);
        serviceresponse = error;
      });
    commit("TOGGLE_LOADING", false);
    return serviceresponse;
  },

  async postPayment({ commit, dispatch, state }, payload: any) {
    commit("TOGGLE_LOADING", true);
    let serviceresponse = null;
    await service
      .Pay(payload)
      .then((response: any) => {
        if (response) {
          commit("TOGGLE_SUCCESS", true);
          commit("SET_RECORDID", response.recordId);
          serviceresponse = response;
          const notification = {
            type: "success",
            message: `Payment Submitted Successfully! Confirmation number: ${response.recordId}`,
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          const notification2 = {
            type: "error",
            message: "Invalid Invoice Option",
          };
          commit("TOGGLE_SUCCESS", false);
        }
      })
      .catch((error: any) => {
        commit("TOGGLE_SUCCESS", false);
        commit("SET_ERROR", error.error);
        serviceresponse = {
          error: error.response.data.error, 
        };
      });
    commit("TOGGLE_LOADING", false);
    return serviceresponse;
  },
  clearState({ commit }) {
    commit("CLEAR_STATE");
  },
  fetchPaymentInfo({ commit, dispatch }, { Client, option }) {
    controlService
      .getPaymentInfo(Client, option)
      .then((response: any) => {
        commit("SET_IFRAME_URL", { url: response.cc_gateway_url });
        commit("SET_ACH_ALLOWED", response.ach_allowed);
        commit("SET_CC_ADR_RQRD", response.cc_adr_rqrd);
      });
  },
};

export const payment: Module<PaymentState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
