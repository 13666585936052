import dbStore from "@/store/db/dbStore";
import ClientAPI from "./ClientAPI";

export default class ApiService {
  protected isOffline: boolean;
  protected readonly dbStore?: dbStore;
  constructor(
    databaseName?: string,
    objectStores?: { name: string; keyPath: string }[]
  ) {
    this.isOffline = !window.navigator.onLine;

    window.addEventListener("offline", () => {
      this.isOffline = true;
    });
    window.addEventListener("online", () => {
      this.isOffline = false;
    });
    if (databaseName && objectStores) {
      this.dbStore = new dbStore(databaseName, objectStores);
    }
  }

  async Get(endpoint: string, params: any, 
    successHandler?: (resp: any) => void,
    failureHandler?: (error: any) => void,
    offlineReturnSuccess = true) {
      if (this.isOffline) {
          return new Promise((resolve, reject) => {
              if (offlineReturnSuccess) {
                  resolve({});
              } else {
                  reject("You are offline");
              }
          });
      } else {
          return ClientAPI.Get(endpoint, params, successHandler, failureHandler);
      }
  }

  async Post(
    endpoint: string,
    payload: any,
    params?: any,
    successHandler?: (resp: any) => void,
    failureHandler?: (error: any) => void,
    offlineReturnSuccess = true
  ) {
    if (this.isOffline) {
        return new Promise((resolve, reject) => {
            if (offlineReturnSuccess) {
                resolve({status: "success"});
            } else {
                reject("You are offline");
            }
        });
    } else {
        return ClientAPI.Post(endpoint, payload, params, successHandler, failureHandler);
    }
  }

  async Put(
    endpoint: string,
    payload: any,
    params?: any,
    successHandler?: (resp: any) => void,
    failureHandler?: (error: any) => void,
    offlineReturnSuccess = true
  ) {
    if (this.isOffline) {
        return new Promise((resolve, reject) => {
            if (offlineReturnSuccess) {
                resolve({status: "success"});
            } else {
                reject("You are offline");
            }
        });
    } else {
        return ClientAPI.Put(endpoint, payload, params, successHandler, failureHandler);
    }
  }
}
