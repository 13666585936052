import { AxiosInstance } from "axios";
import axios from "axios";
import Log from "@/types/logs";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class LogService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getLog(id: string, ids: string, Client: string) {
    return ClientAPI.Get(`logs/${id}`, { ids, Client })
  }

  async getOpportunityLogs(id: string, file: string, Client?: string) {
    return ClientAPI.Get(`logs/${id}`, { file, Client });
  }

  // async postOpportunityLog(id: string, file: string, log: Log) {
  //   let token = "";
  //   await store.dispatch("session/fetchToken").then((response) => {
  //     token = response;
  //   });
  //   const authToken = "Bearer " + token;
  //   return this.submitPost(`logs/${id}`, authToken, { file }, log);
  // }

  async postLog(Client: string, log: Log) {
    return ClientAPI.Post("logs", log, { Client })
  }
}
