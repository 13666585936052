import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice'
import router from './router'
import store from './store'
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import posthogPlugin from './plugins/posthog';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@/assets/main.scss';

createApp(App)
    .use(posthogPlugin)
    .use(PrimeVue)
    .use(store)
    .use(router)
    .use(ToastService)
    .use(ConfirmationService)
    .directive('tooltip', Tooltip)
    .mount('#app')