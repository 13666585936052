import ApiService from "./ApiService";
import ClientAPI from "./ClientAPI";

type Part = {
  client: string;
  part_no: string;
  cat?: string;
  code?: string[];
  web_cats?: string;
  fieldnameFilters?: string;
  status?: string[];
  customerId?: string;
  ship_seq?: string;
  customFields?: any;
  rangeStart?: number;
  rangeEnd?: number;
  correls?: string;
  reps?: string[];
  metaData?: string;
}

export default class PartsService extends ApiService {
  private cache = true;
  constructor() {
    super("parts", [
      { name: "prices", keyPath: "part_number" },
      { name: "parts", keyPath: "part_no" },
    ]);
  }

  async getParts(fieldnames?: string) {
    if (this.isOffline) {
      return new Promise((resolve, reject) => {
        this.dbStore?.getRecords("parts", "")
          .then((results) => {
            resolve({ part_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`parts`, { fieldnames: fieldnames })
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "parts",
                (results as { part_items: any[] }).part_items
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getPartsRecords(
    client: string,
    rangeStart: number,
    rangeEnd: number,
    type: string,
    Id: string
  ) {
    if (this.isOffline) {
      const cacheSearchFunction = (value: any) => {
        if ((type === "" || value.type === type) && (Id === "" || value.Id === Id)) {
          return true;
        }
        return false;
      };

      return new Promise((resolve, reject) => {
        this.dbStore?.searchRecords("parts", cacheSearchFunction, rangeStart, rangeEnd)
          .then((results) => {
            resolve({ part_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`parts`, {
          Client: client,
          rangeStart: rangeStart,
          rangeEnd: rangeEnd,
          type: type,
          Id: Id,
        })
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "parts",
                (results as { parts_items: any[] }).parts_items
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getPartsRequest(partsRequest: {
    client: string;
    part_no?: string;
    cat?: string;
    code: string[];
    web_cats?: string;
    fieldnameFilters?: string;
    status?: string[];
    customerId?: string;
    shipSequence?: string;
    customFields?: any;
    rangeStart?: any;
    rangeEnd?: any;
    metaData?: string;
  }) {
    const {
      client,
      part_no,
      cat,
      code,
      web_cats,
      fieldnameFilters,
      status,
      customerId,
      shipSequence,
      customFields,
      rangeStart,
      rangeEnd,
      metaData
    } = partsRequest;

    const params = {
      client,
      part_no: part_no || "",
      cat,
      code,
      web_cats: web_cats,
      fieldnameFilters: fieldnameFilters,
      status,
      customerId,
      ship_seq: shipSequence,
      customFields,
      rangeStart: rangeStart ? rangeStart : 1,
      rangeEnd: rangeEnd ? rangeEnd : 100,
      correls: "std_price avail_qty code image",
      metaData
    };

    if (this.isOffline) {
      const cacheSearchFunction = (value: any) => {
        const webCatsMatch = web_cats ? value.web_category_items && value.web_category_items.some((webCat: any) => web_cats.includes(webCat)) : true;
        const catMatch = cat ? value.category === cat : true;
        const statusMatch = status && status.length > 0 ? status.includes(value.status) : true;

        return webCatsMatch && catMatch && statusMatch && Object.values(value).some((value) => {
          if (value as string) {
            return String(value)
              .toLowerCase()
              .includes(params.part_no.toLowerCase());
          } else {
            return false;
          }
        });
      };

      return new Promise((resolve, reject) => {
        this.dbStore?.searchRecords("parts", cacheSearchFunction, rangeStart ? rangeStart : 1)
          .then((results) => {
            resolve({ parts_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`parts`, params)
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "parts",
                (results as { parts_items: any[] }).parts_items
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getPriceByPartId(
    part: string,
    client: string,
    qty: string,
    code: string,
    cust: string,
    date: string
  ) {
    const params = {
      client,
      qty,
      code,
      cust,
      date,
    };
    return ClientAPI.Get(`price/${part}`, params);
  }

  async getPrices(pricesRequest: {
    client: string;
    part_no?: string;
    cat?: string;
    code: string[];
    web_cats?: string;
    fieldnameFilters?: string;
    status?: string[];
    customerId?: string;
    shipSequence?: string;
    customFields?: any;
    rangeStart?: number;
    rangeEnd?: number;
    correls?: string;
    reps?: string[];
    metaData?: string;
  }) {
    const {
      client,
      part_no,
      cat,
      code,
      web_cats,
      fieldnameFilters,
      status,
      customerId,
      shipSequence,
      customFields,
      rangeStart,
      rangeEnd,
      correls,
      reps,
      metaData,
    } = pricesRequest;

    const params: Part = {
      client,
      part_no: part_no || "",
      cat,
      code,
      web_cats: web_cats,
      fieldnameFilters: fieldnameFilters,
      status,
      customerId,
      ship_seq: shipSequence,
      customFields,
      rangeStart: rangeStart ? rangeStart : 1,
      rangeEnd: rangeEnd ? rangeEnd : 100,
      correls,
      reps,
      metaData
    };

    if (this.isOffline) {
      const cacheSearchFunction = (value: any) => {
        const codesMatch = code && code.length > 0 ? value.code_items && value.code_items.some((codeItem: any) => code.includes(codeItem.code)) : true;
        const webCatsMatch = web_cats && web_cats.length > 0 ? value.web_category && value.web_category.some((webCat: any) => web_cats.includes(webCat)) : true;
        const catMatch = cat ? value.category === cat : true;
        const statusMatch = status && status.length > 0 ? status.includes(value.status) : true;

        return statusMatch && catMatch && codesMatch && webCatsMatch && Object.values(value).some((value) => {
          if (value as string) { 
            return String(value)
              .toLowerCase()
              .includes(params.part_no.toLowerCase());
          } else {
            return false;
          }
        });
      };

      return new Promise((resolve, reject) => {
        this.dbStore?.searchRecords("prices", cacheSearchFunction, rangeStart, rangeEnd)
          .then((results) => {
            resolve({ price_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`prices`, params)
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "prices",
                (results as { price_items: any[] }).price_items
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getPartsBySelection(part_no: string) {
    if (this.isOffline) {
      const cacheSearchFunction = (value: any) => {
        return Object.values(value).some((value) => {
          if (value as string) {
            return String(value)
              .toLowerCase()
              .includes(part_no.toLowerCase());
          } else {
            return false;
          }
        });
      };

      return new Promise((resolve, reject) => {
        this.dbStore?.searchRecords("parts", cacheSearchFunction)
          .then((results) => {
            resolve({ parts_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`parts`, { part_no })
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "parts",
                (results as { parts_items: any[] }).parts_items
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async fetchPartsOptions(
    fieldnames: string,
    part_no: string,
    client: string
  ) {
    const params = {
      fieldnames,
      part_no,
      client,
    };
    return ClientAPI.Get("parts", params);
  }

  async getPart(
    client: string,
    id: string,
    customerId?: string,
    correls?: string
  ) {
    return this.getPartByID({ client, id, customerId: customerId, correls });
  }

  async getPartByID(partRequest: {
    client: string;
    id: string;
    fieldnames?: string;
    correls?: string;
    customerId?: string;
  }) {
    const { client, id, fieldnames, correls, customerId } = partRequest;

    const params = {
      correls,
      fieldnames,
      client,
      customerId,
      upc: true,
    };

    if (this.isOffline) {
      return new Promise((resolve, reject) => {
        this.dbStore?.getRecord("parts", id)
          .then((results) => {
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`part/${id}`, params)
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecord("parts", results);
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getPartsInventory(client: string, id: string, fieldnames: string) {
    const params = {
      id,
      fieldnames,
      client,
      upc: true,
    };
    return ClientAPI.Get(`partSearch`, params);
  }
}
