import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import CustomerInquiryState from "@/types/state/customerInquiry";
import CustomerService from "@/services/CustomerService";
import ContactService from "@/services/ContactService";
import LogService from "@/services/LogService";
import AddressValidation from "@/services/AddressValidation";
import Customer, { basecontact } from "@/types/customer";
import AttachmentService from "@/services/AttachmentService";
import CustomerStateObject from "@/types/CustomerInquiryMap";
import ContractService from "@/services/ContractService";
import TermsService from "@/services/TermsService";
import SOQuotesService from "@/services/SOQuotesService";
import EmailService from "@/services/EmailService";
import ControlService from "@/services/ControlService";
import StaxService from "@/services/StaxService";
import PartsService from "@/services/PartsService";
import InvoiceService from "@/services/InvoiceService";
import pako from "pako";
import GlChartService from "@/services/GlChartsService";
import RMAService from "@/services/RMAService";
import SalesService from "@/services/SalesService";
import HoldCode from "@/types/hold";
import TicketService from "@/services/TicketService";
import CustomerTicketState from "@/types/state/customerTicket";
import Utils from "@/utility/utils";
import { AxiosError } from "axios";

const namespaced = true;
const service = new CustomerService();
const contactService = new ContactService(process.env.VUE_APP_ABSTRACTION_API);
const logService = new LogService(process.env.VUE_APP_ABSTRACTION_API);
const attachmentService = new AttachmentService(process.env.VUE_APP_ABSTRACTION_API);
const contractservice = new ContractService(process.env.VUE_APP_ABSTRACTION_API);
const termsService = new TermsService(process.env.VUE_APP_ABSTRACTION_API);
const controlService = new ControlService();
const validationService = new AddressValidation(process.env.VUE_APP_ABSTRACTION_API);
const emailService = new EmailService(process.env.VUE_APP_ABSTRACTION_API);
const staxService = new StaxService();
const partsService = new PartsService();
const glChartService = new GlChartService();
const soquotesService = new SOQuotesService(process.env.VUE_APP_ABSTRACTION_API);
const rmaService = new RMAService(process.env.VUE_APP_ABSTRACTION_API);
const salesService = new SalesService();
const invoiceService = new InvoiceService();
const ticketService = new TicketService(process.env.VUE_APP_ABSTRACTION_API);

const DEFAULT_INNER_TAB_ROUTE = "/customers/general";

const CUSTOMER: Customer = {
  cust_id: "",
  name: "",
  address_items: [],
  sort_city: "",
  sort_state: "",
  sort_zip: "",
  stamp_date: "",
  sort_country: "",
  phone: "",
  bill_to: "",
  ar_bal: "",
  open_order_bal: "",
  contact_id_items: [],
  change_date_items: [],
  carrier_items: [],
  quicklist_name_items: [],
  attachments_items: [],
  ship_seq_items: [],
  credit_card_no_items: [],
  bank_routing_items: [],
  rebate_type_items: [],
  from_sales_amt_items: [],
  disc: "",
  terms: "",
  currency_code: "",
  credit_limit: "",
  credit_hold: "",
  highest_ar_bal: "",
  avg_ar_bal: "",
  code: "",
  partials_ok: "",
  ship_via: "",
  freight_code: "",
  group_code_items: [],
  notes: "",
  end_user_cntry_items: [],
  rep_items: [],
  website: "",
  mrk_opt_out: "",
  date: "",
  resale: "",
  ecommerce_level: "",
  log_ids: [],
  rma_ids: [],
  ar_ids: [],
  ticket_ids: [],
  inactive_date: "",
  fso_ids: [],
  tax_codes_items: [],
};

// UTILITY FUNCTION
const instantiateCustFields = (custObject: Customer) => {
  for (const [key, value] of Object.entries(CUSTOMER)) {
    if (!(key in custObject)) {
      Object.assign(custObject, {
        [key]: value,
      });
    }
  }
};

export const state: CustomerInquiryState = {
  CustomerMap: [],
  currency: null,
  RebateControls: [],
  MailControls: null,
  CountryControls: [],
  pricingCodes: [],
  shipvia: null,
  groups: null,
  terms: null,
  ContactTypes: [],
  stax: null,
  QLoptions: [],
  FolderNames: [],
  QLparts: [],
  GlAccounts: [],
  custTabIndex: 0,
  holdCodes: [],
  planGroupCodes: [],
  coCodes: null,
  selectedCoCode: null,
  recentlyViewed: [],
  customers: [],
  updatedCustomers: [],
};

export const getters: GetterTree<CustomerInquiryState, RootState> = {
  getCustomer: (state) => (index: number) => {
    return state.CustomerMap[index]?.customer;
  },
  getHoldCodes(state): Array<HoldCode> | null {
    return state.holdCodes;
  },
  getContacts: (state) => (index: number) => {
    return state.CustomerMap[index].contacts;
  },
  getQuotes: (state) => (index: number) => {
    return state.CustomerMap[index].quotes;
  },
  getShipTo: (state) => (index: number) => {
    return state.CustomerMap[index].customer.ship_seq_items;
  },
  getCreditCards: (state) => (index: number) => {
    return state.CustomerMap[index].customer.credit_card_no_items;
  },
  getContactLog: (state) => (index: number) => {
    return state.CustomerMap[index].logs;
  },
  getAttachments: (state) => (index: number) => {
    return state.CustomerMap[index].attachments;
  },
  getCustomerMap(state): Array<CustomerStateObject> {
    return state.CustomerMap;
  },
  getLoadingAttachments: (state) => (index: number) => {
    return state.CustomerMap[index].loadingAttachments;
  },
  getTickets: (state) => (index: number) => {
    return state.CustomerMap[index].tickets!.tickets;
  },
  getTicketsLength: (state) => (index: number) => {
    return state.CustomerMap[index].tickets?.tickets?.length || 0
  },
  getTicketRange: (state) => (index: number) => {    
    return state.CustomerMap[index].tickets?.rangeEnd;
  },
  getTicketsPageIndex: (state) => (index: number) => {
    return state.CustomerMap[index].tickets?.page;
  },
  getTicketsRangeEnd: (state) => (index: number) => {
    return state.CustomerMap[index].tickets?.rangeEnd;
  },
  getTicketsFirstRow: (state) => (index: number) => {
    return state.CustomerMap[index].tickets?.row;
  },
  getMailControls(state) {
    return state.MailControls;
  },
  getContracts: (state) => (index: number) => {
    return state.CustomerMap[index].contracts;
  },
  getTerms: (state) => {
    return state.terms;
  },
  getTerm: (state) => (index: number) => {
    return state.CustomerMap[index].customer.terms;
  },
  getCurrency: (state) => {
    return state.currency;
  },
  getCountries: (state) => {
    return state.CountryControls;
  },
  getPricingCodes: (state) => {
    return state.pricingCodes;
  },
  getShipVia: (state) => {
    return state.shipvia;
  },
  getGroups: (state) => {
    return state.groups;
  },
  getContactTypes: (state) => {
    return state.ContactTypes;
  },
  getRebateControl: (state) => {
    return state.RebateControls;
  },
  getStax: (state) => {
    return state.stax;
  },
  getQLoptions: (state) => {
    return state.QLoptions;
  },
  getFolders: (state) => {
    return state.FolderNames;
  },
  getQLparts: (state) => {
    return state.QLparts;
  },
  getQL: (state) => (index: number) => {
    return state.CustomerMap[index].customer.quicklist_name_items;
  },
  getSales: (state) => (index: number) => {
    return state.CustomerMap[index].customer.from_sales_amt_items;
  },
  getRebates: (state) => (index: number) => {
    return state.CustomerMap[index].customer.rebate_type_items;
  },
  getThirdParty: (state) => (index: number) => {
    return state.CustomerMap[index].customer.carrier_items;
  },
  getCurrentCustTabIndex: (state) => {
    return state.custTabIndex;
  },
  getCurrentCustomer: (state) => {
    if (state.CustomerMap.length) {
      const { cust_id, name } = state.CustomerMap[state.custTabIndex]?.customer;
      return {
        cust_id,
        name,
      };
    }
    return null;
  },
  getInnerTabValue: (state) => {
    return state.CustomerMap[state.custTabIndex].custInnerTabValue || DEFAULT_INNER_TAB_ROUTE;
  },
  getRMAs: (state) => (index: number) => {
    return state.CustomerMap[index].RMAs;
  },
  getSalesOrders: (state) => (index: number) => {
    return state.CustomerMap[index].so_items;
  },
  getSalesOppsIds: (state) => (index: number) => {
    return state.CustomerMap[index].customer.saleopp_ids;
  },
  getSalesQuotesIds: (state) => (index:number) => {
    return state.CustomerMap[index].customer.soquote_ids;
  },
  getSoIds: (state) => (index: number) => {
    return state.CustomerMap[index].customer.so_ids;
  },
  getArIds: (state) => (index: number) => {
    return state.CustomerMap[index].customer.ar_ids;
  },
  getRmaIds: (state) => (index: number) => {
    return state.CustomerMap[index].customer.rma_ids;
  },
  getTicketIds: (state) => (index: number) => {
    return state.CustomerMap[index].customer.ticket_ids;
  },
  getFsoIds: (state) => (index: number) => {
    return state.CustomerMap[index].customer.fso_ids;
  },
  getInvoices: (state) => (index: number) => {
    return state.CustomerMap[index].invoices;
  },
  getRangeEnd: (state) => (index: number) => {
    return state.CustomerMap[index].rangeEnd;
  },
  getRangeStart: (state) => (index: number) => {
    return state.CustomerMap[index].rangeStart;
  },
  getPlanGroupCodes: (state) => {
    return state.planGroupCodes;
  },
  getCompanyCodes: (state) => {
    return state.coCodes;
  },
  getSelectedCoCode: (state) => {
    return state.selectedCoCode;
  },
  getPageIndex: (state) => (index: number) => {
    return state.CustomerMap[index].page;
  },
  getRecentlyViewed(state) {
    return [...state.recentlyViewed].reverse();
  },
  getCustomerById(state, cust_id) {
    return state.customers.find((customer) => customer.cust_id === cust_id);
  },
  getUpdatedCustomerById(state, cust_id) {
    return state.updatedCustomers.find((customer) => customer.cust_id === cust_id);
  },
};

export const mutations: MutationTree<CustomerInquiryState> = {
  SET_SELECTED_CO_CODE(state, code) {
    state.selectedCoCode = code;
  },
  SET_COMPANY_CODES(state, codes) {
    state.coCodes = codes;
  },
  SET_CONTACTS(state, { contacts, index }) {
    state.CustomerMap[index].contacts = contacts;
  },
  SET_UPDATED_CONTACT(state, { action, contacts, contactIdItems, index }) {
    state.CustomerMap[index].contacts = contacts;

    switch (action) {
      case "NEW": {
        state.CustomerMap[index].customer.contact_id_items?.push({
          contact_id: contactIdItems.contact_id,
          contact_portal: contactIdItems.portal || contactIdItems.contact_portal,
          contact_type: contactIdItems.type || contactIdItems.contact_type,
        });
        break;
      }
      case "EDIT": {
        const contactIndex = state.CustomerMap[index].customer.contact_id_items?.findIndex(
          (contact) => {
            return contact.contact_id == contactIdItems.contact_id;
          }
        );

        if (contactIndex !== -1) {
          state.CustomerMap[index].customer.contact_id_items[contactIndex] = {
            contact_id: contactIdItems.contact_id,
            contact_portal: contactIdItems.portal,
            contact_type: contactIdItems.type,
          };
        }
        break;
      }
      case "DELETE": {
        state.CustomerMap[index].customer.contact_id_items = state.CustomerMap[
          index
        ].customer.contact_id_items.filter((contact) => {
          return contact.contact_id !== contactIdItems.contact_id;
        });
        break;
      }
      default:
        break;
    }
    state.updatedCustomers[index].contact_id_items = state.CustomerMap[index].customer.contact_id_items;
    state.customers[index].contact_id_items = state.CustomerMap[index].customer.contact_id_items;
  },
  SET_HOLD_CODE(state, codes) {
    if (codes == undefined) codes = [];
    state.holdCodes = codes;
  },
  SET_QUOTES(state, { quotes, index }) {
    state.CustomerMap[index].quotes = quotes;
  },
  SET_LOGS(state, { logs, index }) {
    state.CustomerMap[index].logs = logs;
  },
  SET_ATTACHMENTS(state, { attachments, index }) {
    state.CustomerMap[index].attachments = attachments;
  },
  SET_CUST_TAB_INDEX(state, tabIndex: number) {
    state.custTabIndex = tabIndex - 1;
  },
  SET_SALES_ORDERS(state, { orders, index }) {
    state.CustomerMap[index].so_items = orders.so_items;
  },
  REMOVE_CUSTOMER(state, customerstate: CustomerStateObject) {
    const filteredIndex = state.CustomerMap.indexOf(customerstate);

    const filtered = state.CustomerMap.filter((item) => {
      return item.customer.cust_id != customerstate.customer.cust_id;
    });

    if (
      filteredIndex <= state.custTabIndex &&
      (filteredIndex !== 0 || filteredIndex < state.custTabIndex)
    ) {
      state.custTabIndex = state.custTabIndex - 1;
    } else if (filteredIndex == 0) {
      state.custTabIndex = 0;
    }
    state.customers = state.customers.filter((item) => item.cust_id === customerstate.customer.cust_id);
    state.updatedCustomers = state.updatedCustomers.filter((item) => item.cust_id === customerstate.customer.cust_id);
    state.CustomerMap = filtered;
  },
  PUSH_CUSTOMER(state, customer: Customer) {
    const custState: CustomerStateObject = {
      customer,
      logs: null,
      contacts: null,
      attachments: null,
      loading: false,
      loadingAttachments: null,
      contracts: null,
      RMAs: null,
      custInnerTabValue: DEFAULT_INNER_TAB_ROUTE,
      quotes: null,
      so_items: null,
      invoices: null,
      tickets: {
        tickets: [],
        rangeStart: 1,
        rangeEnd: 100,
        page: 0,
        row: 1,
        ticketsPerPage: 5,
        currentIndex: -1
      } as CustomerTicketState,
      page: 1,
      rangeStart: 1,
      rangeEnd: 100,
    };
    state.customers = [...state.customers, customer];
    state.CustomerMap = [custState, ...state.CustomerMap];
    state.custTabIndex = 0;
  },
  SET_TICKETS(state, { tickets, index }) {
    state.CustomerMap[index].tickets!.tickets?.push(...tickets);
  },
  ADD_ID_LOADING(state, { id, index }) {
    if (state.CustomerMap[index].loadingAttachments == null) {
      state.CustomerMap[index].loadingAttachments = [];
    }
    state.CustomerMap[index].loadingAttachments?.push(id);
  },
  REMOVE_ID_LOADING(state, { id, index }) {
    const attachIndex = state.CustomerMap[index].loadingAttachments?.indexOf(id);
    if (attachIndex != null) {
      if (index > -1 && attachIndex > -1) {
        state.CustomerMap[index].loadingAttachments?.splice(attachIndex, 1);
      }
    }
  },
  SET_CONTRACTS(state, { contracts, index }) {
    state.CustomerMap[index].contracts = contracts;
  },
  SET_TERMS(state, { terms }) {
    state.terms = terms;
  },
  SET_CONTROL(state, { control, response }) {
    switch (control as string) {
      case "currency":
        state.currency = response[0].currency_code_items;
        break;
      default:
        break;
    }
  },
  SET_INNER_TAB_ITEM(state, tabName: string) {
    state.CustomerMap[state.custTabIndex].custInnerTabValue = tabName;
  },
  DEL_ATTACHMENT(state, { atchId, index }) {
    if (state.CustomerMap.length != 0) {
      if (index > -1) {
        const objIndex = state.CustomerMap[index].customer.attachments_items.findIndex(
          (attachment) => attachment.attachments === atchId
        );
        if (objIndex != -1) {
          state.CustomerMap[index].customer.attachments_items.splice(objIndex, 1);
        }
        // const objIndex2 = state.CustomerMap[index].attachments?.findIndex(
        //   (attachment) => attachment.id === atchId
        // );
        // if (objIndex2 !== undefined) {
        //   if (objIndex2 > -1) {
        //     state.CustomerMap[index].attachments?.splice(objIndex2, 1);
        //   }
        // }
      }
    }
  },
  ADD_ATTACHMENT(state, { attachmentId, description, index }) {
    const custCopy = state.CustomerMap[index].customer.attachments_items;
    // const attchCopy = state.CustomerMap[index].attachments;
    // attchCopy?.unshift(attachment);
    custCopy.unshift({ attachments: attachmentId, attachment_desc: description });
    // state.CustomerMap[index].attachments = attchCopy;
    state.CustomerMap[index].customer.attachments_items = custCopy;
  },
  CLEAR_ATTACHMENTS(state, { index }) {
    state.CustomerMap[index].attachments = [];
  },
  PUSH_REBATE(state, rebates) {
    state.RebateControls = rebates;
  },
  PUSH_MAIL(state, mail) {
    state.MailControls = mail;
  },
  PUSH_COUNTRY(state, countries) {
    state.CountryControls = countries;
  },
  PUSH_CURRENCY(state, currency) {
    state.currency = currency;
  },
  PUSH_PRICE(state, price) {
    state.pricingCodes = price;
  },
  SET_SHIPVIA(state, shipvia) {
    state.shipvia = shipvia;
  },
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },
  PUSH_CONTACT_TYPE(state, types) {
    state.ContactTypes = types;
  },
  PUSH_FOLDERS(state, folders) {
    state.FolderNames = folders;
  },
  SET_STAX(state, stax) {
    state.stax = stax;
  },
  SET_CREDIT_CARDS(state, { data, index }) {
    state.updatedCustomers[index].credit_card_no_items = data;
  },
  SET_BANK_ACCOUNTS(state, {data, index}) {
    state.updatedCustomers[index].bank_routing_items = data
  },
  PUSH_QL_OPTS(state, opts) {
    state.QLoptions = opts;
  },
  SET_QL_PARTS(state, parts) {
    state.QLparts = parts;
  },
  SET_ACCOUNTS(state, accounts) {
    state.GlAccounts = accounts;
  },
  SET_CUSTOMER(state, { data, index }) {
    const customer = state.updatedCustomers[index] as any;
    Object.keys(customer).forEach((property) => {
      customer[property] = data[property] == null ? customer[property] : data[property];
    });
  },
  SET_SHIPTO(state, { data, index }) {
    state.updatedCustomers[index].ship_seq_items = data;
  },
  SET_RMAS(state, { rmas, index }) {
    state.CustomerMap[index].RMAs = rmas;
  },
  SET_INVOICES(state, { invoices, index }) {
    if (state.CustomerMap[index].invoices != null)
      state.CustomerMap[index].invoices!.push(...invoices) || [];
    else state.CustomerMap[index].invoices = invoices;
  },
  SET_NEXT_PAGE(state, { pageNumber, index }) {
    state.CustomerMap[index].page = pageNumber;
  },
  TICKETS_NEXT_PAGE(state, { pageNumber, index }){
    state.CustomerMap[index].tickets!.page = pageNumber;
  },
  SET_TICKETS_FIRST_ROW(state, { index, row }) {
    state.CustomerMap[index].tickets!.row = row;
  },
  RESET_TICKETS_PAGE(state, { index }) {
    state.CustomerMap[index].tickets!.page = 0;
    state.CustomerMap[index].tickets!.rangeStart = 1;
    state.CustomerMap[index].tickets!.rangeEnd = 100;
  },
  CLEAR_TICKETS(state, { index }) {
    state.CustomerMap[index].tickets!.tickets = [];
  },
  TICKETS_NEXT_RANGE(state, { index }) {
    state.CustomerMap[index].tickets!.rangeStart += 100;
    state.CustomerMap[index].tickets!.rangeEnd += 100;
  },
  SET_NEXT_RANGE(state, { index }) {
    state.CustomerMap[index].rangeStart += 100;
    state.CustomerMap[index].rangeEnd += 100;
  },
  RESET_RANGE(state, { index }) {
    state.CustomerMap[index].page = 1;
  },
  RESET_PAGE(state, { index }) {
    state.CustomerMap[index].page = 1;
    state.CustomerMap[index].rangeStart = 1;
    state.CustomerMap[index].rangeEnd = 100;
  },
  CLEAR_INVOICES(state, { index }) {
    state.CustomerMap[index].invoices = [];
  },
  SET_PLAN_CODES(state, codes) {
    state.planGroupCodes = codes;
  },
  PUSH_RECENTLY_VIEWED(state, customer) {
    state.recentlyViewed.push(customer);
  },
  REMOVE_RECENTLY_VIEWED_BY_INDEX(state, index) {
      state.recentlyViewed.splice(index, 1);
  },
  PUSH_SALE_OPPS_ID(state, {index, id}) {
    if(state.CustomerMap[index]) {
      state.CustomerMap[index].customer.saleopp_ids.push(id)
    }
  },
  PUSH_SALE_QUOTES_ID(state, {index, id}) {
    if(state.CustomerMap[index]) {
      state.CustomerMap[index].customer.soquote_ids.push(id)
    }
  },
  PUSH_TICKETS_ID(state, {index, id}) {
    if(state.CustomerMap[index]) {
      state.CustomerMap[index].customer.ticket_ids.push(id)
    }
  },
  PUSH_SALES_ID(state, {index, id}) {
    if(state.CustomerMap[index]) {
      state.CustomerMap[index].customer.so_ids.push(id)
    }
  },
  ADD_NEW_CUSTOMER(state, customer) {
    state.customers = [...state.customers, customer];
  },
  REMOVE_CUSTOMER_2(state, customer: Customer) {
    state.customers = state.customers.filter((c) => c.cust_id !== customer.cust_id);
  },
  ADD_UPDATED_CUSTOMER(state, customer) {
    state.updatedCustomers = [...state.updatedCustomers, customer];
  },
  UPDATE_CUSTOMER(state, customer: Customer) {
    const index = state.updatedCustomers.findIndex((c) => c.cust_id === customer.cust_id);

    if(index >= 0) {
      state.updatedCustomers[index] = customer;
    } else {
      state.updatedCustomers = [...state.updatedCustomers, customer];
    }
  },
  SYNC_CUSTOMER(state, cust_id: string) {
    const index = state.updatedCustomers.findIndex((c) => c.cust_id === cust_id);

    if(index >= 0) {
      const customer = state.updatedCustomers[index];
      const oldIndex = state.CustomerMap.findIndex((c) => c.customer.cust_id === cust_id);
      if(oldIndex >= 0) {
        state.CustomerMap[oldIndex].customer = customer;
      }
    }
  },
  UPDATE_CUSTOMER_STATE(state, customer: Customer) {
    const oldIndex = state.updatedCustomers.findIndex((c) => c.cust_id === customer.cust_id);

    if(oldIndex >= 0) {
      state.updatedCustomers[oldIndex] = customer;
    } else {
      state.updatedCustomers = [...state.updatedCustomers, customer];
    }

    const newIndex = state.customers.findIndex((c) => c.cust_id === customer.cust_id);

    if(newIndex >= 0) {
      state.customers[newIndex] = customer;
    } else {
      state.customers = [...state.customers, customer];
    }

    const mapIndex = state.CustomerMap.findIndex((c) => c.customer.cust_id === customer.cust_id);

    if(mapIndex >= 0) {
      state.CustomerMap[mapIndex].customer = customer;
    }

  },
  REMOVE_UPDATED_CUSTOMER(state, customer: Customer) {
    state.updatedCustomers = state.updatedCustomers.filter((c) => c.cust_id !== customer.cust_id);
  }
};

export const actions: ActionTree<CustomerInquiryState, RootState> = {
  setSelectedCoCode({ commit }, code) {
    commit("SET_SELECTED_CO_CODE", code);
  },
  pushSaleOppsId({ commit }, {index, id}) {
    commit("PUSH_SALE_OPPS_ID", {index, id})
  },
  pushSaleQuotesId({ commit }, {index, id}) {
    commit("PUSH_SALE_QUOTES_ID", {index, id})
  },
  pushTicketsId({ commit }, {index, id}) {
    commit("PUSH_TICKETS_ID", {index, id})
  },
  pushSalesId({ commit }, {index, id}){
    commit("PUSH_SALES_ID", {index, id})
  },
  ticketsNextPage({commit},{pageNumber, index}){
    commit("TICKETS_NEXT_PAGE", {pageNumber, index})
  },
  ticketsSetNextRow({ commit }, { row, index }) {
    commit("SET_TICKETS_FIRST_ROW", { row, index });
  },
  async getCustomer({ commit, dispatch, state }, { id, Client, correls }) {
    return new Promise((resolve, reject) => {
      const item = getByNameCustomerMap(state, id);
      if (item) {
        resolve({ success: true, data: item });
      } else {
        service.getCustomer(id, Client, correls)
          .then((response: any) => {
            if (response) {
              instantiateCustFields(response);
              commit("PUSH_CUSTOMER", response as Customer);
              dispatch("attachments/addAttachmentState", '', { root: true });
              resolve({ success: true, data: getByNameCustomerMap(state, id) });
            } else {
              const notification = {
                type: "error",
                message: "Invalid Customer",
              };
              reject({ success: false, error: "Invalid Customer" });
              dispatch("notification/add", notification, { root: true });
            }
          })
          .catch((error) => {
            reject({ success: false, error });
          });
      }
    });
  },
  getContacts({ state, commit }, { Client, index }) {
    return new Promise((resolve, reject) => {
      const contact_id_items = state.CustomerMap[index].customer.contact_id_items;
      if (contact_id_items) {
        const contact_ids = contact_id_items.map((item: basecontact) => item.contact_id);
        const ids = contact_ids.join(" ");
        contactService
          .getContacts(ids, Client)
          .then((response: any) => {
            commit("SET_CONTACTS", { contacts: response, index });
            resolve({ success: true });
          })
          .catch(() => {
            reject({ success: false });
          });
      } else {
        commit("SET_CONTACTS", { contacts: [], index });
        resolve({ success: true, message: "No Contacts linked to this customer." });
      }
    });
  },
  getRMAs({ state, commit }, { Client, index, custId }) {
    let ids = "";
        state.CustomerMap[index].customer.rma_ids.forEach((id: string) => {
          ids += id+ " ";
        });
    return new Promise((resolve, reject) => {
      rmaService
        .getRMAs(Client, custId, ids)
        .then((response: any) => {
          commit("SET_RMAS", { rmas: response, index });
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error });
        });
    });
  },
  fetchSalesOrders({ state, commit, dispatch }, { index, id, custId, status, correls, date }) {
    return new Promise((resolve, reject) => {
      salesService
        .getOrders(custId, id, status, correls, date)
        .then((response) => {
          commit("SET_SALES_ORDERS", { orders: response, index });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTickets({ state, commit }, { custs, ids, status, addTicket, index, assignee, assignees, correls, types }) {
    return new Promise((resolve, reject) => {
        if (!addTicket) {
          commit("RESET_TICKETS_PAGE", { index });
        }
        ticketService
          .getTicketsSearch(state.CustomerMap[index]?.tickets?.rangeStart, state.CustomerMap[index]?.tickets?.rangeEnd, custs, ids, status, assignee, assignees, "", "", correls, types)
          .then((response: any) => {
            if (!addTicket) {
              commit("CLEAR_TICKETS", { index });
            }
            commit("SET_TICKETS", { tickets: response.ticket_items, index});
            commit("TICKETS_NEXT_RANGE", { index });
            resolve({ success: true });
          })
          .catch((error) => {
            reject({ success: false, error: error });
          });
    });
  },
  updateContactFields({ commit }, { action, contacts, newContact, contactIdItems, index }) {
    commit("SET_UPDATED_CONTACT", { action, contacts, newContact, contactIdItems, index });
  },
  async getAttachments({ commit, dispatch }, payload) {
    const {ids,Client,index,isAddingAttachments} = payload;
    if (!isAddingAttachments) {
      commit("RESET_PAGE", { index: index });
    }
    await attachmentService.getAttachments(ids, state.CustomerMap[index].rangeStart, state.CustomerMap[index].rangeEnd)
    .then((resp: any) => {
      if (!isAddingAttachments) {
        commit("CLEAR_ATTACHMENTS", { index: index });
      }
      commit("SET_ATTACHMENTS", {
        attachments: resp.attachment_items || [],
        index: index,
      });
      commit("SET_NEXT_RANGE", { index });
    });
  },
  fetchInvoices({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const { custId, dateRange, Client, index, statuses, types, id, sortBy, isAddingInvoices } = payload;

      let dateStart;
      let dateEnd;

      let ids = id;
      if(ids === '') {
        state.CustomerMap[index].customer.ar_ids.forEach((id: string) => {
          ids += id+ " ";
        });
      } 
      if (dateRange == null || dateRange.length === 0) {
        dateStart = dateEnd = undefined;
      } else if (dateRange[0] != null && dateRange[1] != null) {
        dateStart = Utils.formatDate(dateRange[0]);
        dateEnd = Utils.formatDate(dateRange[1]);
      } else if (dateRange[0] != null) {
        dateStart = Utils.formatDate(dateRange[0]);
      }

      if (!isAddingInvoices) {
        commit("RESET_PAGE", { index: index });
      }

      invoiceService.setAllInvoices(
        custId,
        Client,
        dateStart,
        dateEnd,
        '',
        statuses,
        sortBy,
        ids,
        '',
        types
      )
      .then((response: any) => {
        if (!isAddingInvoices) {
          commit("CLEAR_INVOICES", { index: index });
        }
        commit("SET_INVOICES", { invoices: response.ar_items, index: index });
        commit("SET_NEXT_RANGE", { index });
        resolve({ success: true });
      })
      .catch((error) => reject({ success: false }));
    });
  },
  async addContact({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      contactService
        .newContact(payload)
        .then((response: any) => {
          if (payload.contact_id === "" || payload.contact_id === null) {
            const notification = {
              type: "success",
              message: "Successfully added Contact",
            };
            dispatch("notification/add", notification, { root: true });
            resolve(response);
          } else {
            const notification = {
              type: "success",
              message: "Successfully updated Contact",
            };
            dispatch("notification/add", notification, { root: true });
            resolve(response);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  async updateContact({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      contactService
        .updateContact(payload.oldContact, payload.contact, payload.client)
        .then((response: any) => {
          if (response.status == "success") {
            resolve({ success: true, recordId: response.recordId });
          }
        })
        .catch((error: any) => {
          reject({ success: false });
        });
    });
  },
  fetchQuotes({ commit }, { cust, rangeStart, rangeEnd, status, index, correls}) {
    return new Promise((resolve, reject) => {
      soquotesService
        .getSOQuotes("", cust, rangeStart, rangeEnd, status, correls)
        .then((response: any) => {
          commit("SET_QUOTES", { quotes: response.soquote_items, index });
          resolve({ success: true });
        })
        .catch(() => {
          reject({ success: false });
        });
    });
  },
  async resetContactPwd({ dispatch }, payload) {
    contactService
    .resetPwd(payload)
    .then((response: any) => {
      if (response.status == "success") {
        const notification = {
          type: "success",
          message: `Contact ${payload.Id} password has been successfully reset`,
        };
        dispatch("notification/add", notification, { root: true });
      } else {
        const notification2 = {
          type: "error",
          message: response.error,
        };
        dispatch("notification/add", notification2, { root: true });
      }
    });
  },
  async getContactLog({ state, commit }, { Client, index }) {
    if (state.CustomerMap[index].customer != null) {
      let ids = "";
        state.CustomerMap[index].customer.log_ids.forEach((log) => {
          ids += log+ " ";
        });
      await logService
        .getLog(state.CustomerMap[index].customer.cust_id || "0", ids, Client)
        .then((response: any) => {
          commit("SET_LOGS", { logs: response.log_items, index });
        });
    }
  },
  async deleteAttachment({ commit, dispatch, state }, payload) {
    // await attachmentService
    //   .deleteAttachment(payload)
    //   .then((response: any) => {
    //     if (response.status == "success") {
          commit("DEL_ATTACHMENT", {
            atchId: payload.attachment_id,
            index: payload.index,
          });
      //   } else {
      //     const notification = {
      //       type: "error",
      //       message: response.error,
      //     };
      //     dispatch("notification/add", notification, { root: true });
      //   }
      // });
  },
  async emailAttachment({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      emailService
        .email(payload)
        .then((resp: any) => {
          if (resp.status === "failed") {
            const notification = {
              type: "error",
              message: resp.message,
            };
            dispatch("notification/add", notification, { root: true });
            reject({ success: false, error: resp.message });
          } else {
            const notification = {
              type: "success",
              message: "Email Sent",
            };
            dispatch("notification/add", notification, { root: true });
            resolve({ success: true });
          }
        })
        .catch((error: any) => { reject({ success: false, error }) });
    });
  },
  async downloadAttachment({ commit, dispatch, state }, payload) {
    commit("ADD_ID_LOADING", { id: payload.id, index: payload.index });
    const base64 =
      state.CustomerMap[payload.index].attachments?.find((e) => e.id === payload.id)?.base64 ||
      null;
    let mime_type = state.CustomerMap[payload.index].attachments?.find(
      (e) => e.id === payload.id
    )?.mime_type;
    if (!mime_type) {
      mime_type = "text/plain";
    }
    await attachmentService
      .downloadAttachment(payload.id)
      .then((resp: any) => {
        if (!resp.error) {
          try {
            const decoded = atob(resp.document);
            const len = decoded.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = decoded.charCodeAt(i);
            }
            const unzipped = pako.ungzip(bytes);
            const blobStore = new Blob([unzipped], { type: mime_type });
            const data = URL.createObjectURL(blobStore);
            const link = document.createElement("a");
            link.href = data;
            link.download = resp.fileName;
            link.click();
          } catch {
            const notification = {
              type: "error",
              message: resp.error,
            };
            dispatch("notification/add", notification, { root: true });
          }
          commit("REMOVE_ID_LOADING", { id: payload.id, index: payload.index });
        }
      });
  },
  saveInnerTabItem({ commit }, tabName: string) {
    commit("SET_INNER_TAB_ITEM", tabName);
  },
  saveCustTabIndex({ commit }, tabIndex: number) {
    commit("SET_CUST_TAB_INDEX", tabIndex);
  },
  removeCustomer({ state, commit, dispatch }, name: CustomerStateObject) {
    const index = state.CustomerMap.indexOf(name);
    dispatch("attachments/removeAttachmentStateObject", index, { root: true });
    commit("REMOVE_CUSTOMER", name);
  },
  addCustomer({ commit }, customerstate: CustomerStateObject) {
    commit("PUSH_CUSTOMER", customerstate);
  },
  updatePage({ commit }, { pageNumber, index }) {
    commit("SET_NEXT_PAGE", { pageNumber, index });
  },
  updateCustomer({ dispatch }, { custId, oldCust, newCust, Client, populateBlankMailingAddress }) {
    return new Promise((resolve, reject) => {
      service
        .updateCustomer(custId, oldCust, newCust, Client, "", populateBlankMailingAddress)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: AxiosError) => { reject(error)});
    });
  },
  updateCustomerAction({ state, commit }, { custId, Client }) {    
    const oldCust = state.customers.find((e) => e.cust_id === custId);
    const newCust = state.updatedCustomers.find((e) => e.cust_id === custId);    
    if(oldCust && newCust) {
      return new Promise((resolve, reject) => {
        service
          .updateCustomer(custId, oldCust, newCust, Client)
          .then((response: any) => {
            commit("UPDATE_CUSTOMER_STATE", newCust);
            const customer = state.CustomerMap.find((e) => e.customer.cust_id === custId);
            if(customer) {
              commit("REMOVE_CUSTOMER", customer);
            }
            resolve(response);
          })
          .catch((error: AxiosError) => { reject(error)});
      });
    } else {
      return new Promise((_, reject) => {
        reject("Customer not found");
      });
    }
  },
  newCustomer({ dispatch, rootGetters }, { newCust }) {
    return new Promise((resolve, reject) => {
      service
        .newCustomer(newCust)
        .then((response: any) => {
          if (response.status === "success") {
            dispatch("getCustomer", {
              id: response.recordId,
            })
              .then(() => {
                resolve({ success: true, cust_id: response.recordId });
              })
              .catch(() => {
                reject({ success: false, error: `Error Fetching customer` });
              });
          } else {
            reject({ success: false, error: "Error Creating Customer" });
          }
        })
        .catch(() => {
          reject({ success: false, error: "Error Creating Customer" });
        });
    });
  },
  async getContracts({ commit }, { id, client, index }) {
    await contractservice.getContracts(id, client).then((response: any) => {
      if (response) {
        commit("SET_CONTRACTS", { contracts: response.cprice_items, index });
      }
    });
  },
  async getTerms({ commit }) {
    await termsService.getTerms().then((response: any) => {
      if (response) {
        commit("SET_TERMS", { terms: response });
      }
    });
  },
  validateCustomerAddress({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      validationService
        .validateCustomerAddress(payload)
        .then((response: any) => {
          if (response[0].status == "verified") {
            const notification = {
              type: "success",
              message: "Valid Address",
            };
            dispatch("notification/add", notification, { root: true });
            resolve({ verified: true, success: false });
          } else if (response[0].status == "unverified") {
            const notification = {
              type: "unverified",
              message: "Address not Valid",
            };
            dispatch("notification/add", notification, { root: true });
            resolve({ verified: true, success: false });
          } else if (response[0].status == "warning") {
            const notification = {
              type: "warning",
              message: "Double Check the Address",
            };
            dispatch("notification/add", notification, { root: true });
            resolve({ verified: true, success: false });
          } else {
            const notification = {
              type: "error",
              message: "Unable to validate address",
            };
            dispatch("notification/add", notification, { root: true });
            resolve({ verified: true, success: false });
          }
        })
        .catch((error) => {
          reject({ verified: false, success: false, error: error.error || error });
          dispatch(
            "notification/add",
            { type: "error", message: `Verification Failed: ${error.error || error}` },
            { root: true }
          );
        });
    });
  },
  async getControls({ commit, dispatch }, payload) {
    if (payload.fieldnames == null) {
      payload.fieldnames = "";
    }
    await controlService.getControl(payload.id, payload.procedure, payload.filename, payload.fieldnames)
    .then((response: any) => {
      switch (payload.id) {
        case "REBATE":
          commit("PUSH_REBATE", response[0].rebate_code_items);
          break;
        case "MAIL":
          commit("PUSH_MAIL", response[0]);
          break;
        case "CURRENCY":
          if (!response[0]) {
            commit("PUSH_CURRENCY", []);
          } else {
            commit("PUSH_CURRENCY", response[0].currency_code_items);
          }
          break;
        case "PRICE":
          if (!response[0]) {
            commit("PUSH_PRICE", []);
            break;
          } else {
            commit("PUSH_PRICE", response[0].code_items);
            break;
          }
        case "SHIP":
          if (!response[0]) {
            commit("SET_SHIPVIA", []);
            commit("PUSH_COUNTRY", []);
          }
          if (response[0] && response[0].ship_via_items) {
            commit("SET_SHIPVIA", response[0].ship_via_items);
          }
          if (response[0] && response[0].country_items) {
            commit("PUSH_COUNTRY", response[0].country_items);
          }
          break;
        case "GROUP":
          if (!response[0]) {
            commit("SET_GROUPS", []);
          } else {
            commit("SET_GROUPS", response[0].code_items);
          }
          break;
        case "CONTACT":
          if (!response[0]) {
            commit("PUSH_CONTACT_TYPE", []);
          } else {
            commit("PUSH_CONTACT_TYPE", response[0].contact_id_items);
          }

          break;
        case "WEB":
          commit("PUSH_QL_OPTS", response[0].quick_list_names_items);
          break;
        case "ATTACHMENT":
          commit("PUSH_FOLDERS", response[0].folder_name_items);
          break;
        case "MRK":
          if (!response[0]) {
            commit("SET_HOLD_CODE", []);
          } else {
            commit("SET_HOLD_CODE", response[0].hold_code_items);
          }
          break;
        case "PLAN":
          if (!response[0]) {
            commit("SET_PLAN_CODES", []);
          } else {
            commit("SET_PLAN_CODES", response[0].plan_group_items);
          }
          break;
        case "COMPANY":
          if (!response[0]) {
            commit("SET_COMPANY_CODES", []);
          } else {
            commit("SET_COMPANY_CODES", response[0].code_items);
          }
          break;
        case "BILL":
          if (!response[0]) {
            commit("SET_USER_DATA", []);
          } else {
            commit("SET_USER_DATA", response[0].user_id_items);
          }
          break;
        default:
          break;
      }
    })
  },
  async addAttachment({ commit, dispatch, state }, payload) {
    // return new Promise((resolve, reject) => {
    //   const zipped: Uint8Array = pako.gzip(payload.doc);
    //   const len = zipped.byteLength;
    //   let zippedString = "";
    //   for (let i = 0; i < len; i++) {
    //     zippedString += String.fromCharCode(zipped[i]);
    //   }
    //   const b64 = btoa(zippedString);
    //   payload.doc = b64;
    //   attachmentService
    //     .addAttachment(payload)
    //     .then((response: any) => {
    //       if (response.status === "success") {
            commit("ADD_ATTACHMENT", {
              attachmentId: payload.attachmentId,
              description: payload.description,
              index: payload.index
              // attachment: {
              //   id: response.attachment.id,
              //   date_created: response.attachment.date_created,
              //   time_created: response.attachment.time_created,
              //   file_name: payload.doc_name,
              //   created_by: payload.user_id,
              // },
              // desc: payload.desc,
              // index: index,
            });
    //       } else {
    //         const notification = {
    //           type: "error",
    //           message: response.error,
    //         };
    //         dispatch("notification/add", notification, { root: true });
    //       }
    //       resolve({ success: true });
    //     })
    //     .catch((error) => {
    //       const notification = {
    //         type: "error",
    //         message: error,
    //       };
    //       dispatch("notification/add", notification, { root: true });
    //       reject({ success: false });
    //     });
    // });
  },
  postLog({ commit, dispatch, state }, { client, log }) {
    return new Promise((resolve, reject) => {
      logService
        .postLog(client, log)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getStax({ commit }, { client }) {
    staxService.getStax(client).then((response: any) => {
      commit("SET_STAX", response.stax_items);
    });
  },
  updateField({ commit }, { field, data, index }) {
    switch (field) {
      case "CREDIT":
        commit("SET_CREDIT_CARDS", { data, index });
        break;
      case "ACH":
        commit("SET_BANK_ACCOUNTS", { data, index });
        break;
      case "CUSTOMER":
        commit("SET_CUSTOMER", { data, index });
        break;
      case "SHIPTO":
        commit("SET_SHIPTO", { data, index });
        break;
      case "CONTACT":
        commit("SET_CONTACTS", { contacts: data, index });
        break;
      default:
        break;
    }
  },
  getParts({ commit }, { fieldnames, client }) {
    partsService.getParts(fieldnames).then((response: any) => {
      commit("SET_QL_PARTS", response.parts_items);
    });
  },
  getAccounts({ commit }, { fieldnames, client }) {
    glChartService.getAccounts(fieldnames, client).then((response: any) => {
      commit("SET_ACCOUNTS", response.glchart_items);
    });
  },
  addRecentlyViewed({ commit, state }, customer) {
    // Find Index of Exisiting Customer in Recently Viewed
    const existingViewedIndex = state.recentlyViewed.findIndex((cust) => {
        return cust.cust_id === customer.cust_id;
    })
    // Check If Customer Esist or Not
    if (existingViewedIndex !== -1) {
        commit("REMOVE_RECENTLY_VIEWED_BY_INDEX", existingViewedIndex);
    }
    commit("PUSH_RECENTLY_VIEWED", customer);
  },
  removeRecentlyViewedCustomer({ commit, state }, id) {
      // Find Index of Customer in Recently Viewed By Id
      const index = state.recentlyViewed.findIndex((cust: Customer) => {
          return cust.cust_id === id;
      })
      commit("REMOVE_RECENTLY_VIEWED_BY_INDEX", index);
  },
  updateCustomerObject({ commit, state }, customer) {
    commit("UPDATE_CUSTOMER", customer);
  },
  syncCustomer({ commit, state }, cust_id) {
    commit("SYNC_CUSTOMER", cust_id);
  }
};
export const customerInquiry: Module<CustomerInquiryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

function getByNameCustomerMap(state: CustomerInquiryState, name: string) {
  const helper = state.CustomerMap;
  const find = helper.find((item: CustomerStateObject) => {
    return item.customer.cust_id == name;
  });
  return find;
}

