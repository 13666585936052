import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import ClientAPI from "./ClientAPI";

export default class TransactionService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getTransaction(part?: string, ids?: string, rangeStart?: number,rangeEnd?: number) {
    const params = {
        part,
        ids,
        rangeStart,
        rangeEnd
    };
    return ClientAPI.Get(`transactions`, params)
  }

}
