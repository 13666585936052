import * as jose from 'jose';

const SECRET: string = process.env.VUE_APP_DASHBOARD_SECRET || "";

export default class JWTService {
    static async generateToken(payload: any): Promise<string> {

        if(SECRET) {
            const secret = new TextEncoder().encode(SECRET);
            const alg = 'HS256'
    
            const jwt = await new jose.SignJWT(payload)
                .setProtectedHeader({ alg })
                .setIssuedAt()
                .sign(secret)
    
            return jwt;
        } else {
            console.log("Dashboard token not created.")
            return "";
        }
    }
}