import SalesInquiryState from "@/types/state/salesInquiry"
import { RootState } from "@/types/state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { LisItems } from "@/types/salesorder"
import router from "@/router";

const namespaced = true;

export const state: SalesInquiryState = {
  SalesInquiryMap: [],
  activeTab: 0,
}

export const getters: GetterTree<SalesInquiryState, RootState> = {
  getNewOppsCount(state) {
    return state.SalesInquiryMap.filter((item: any) => item.id && item.id.startsWith("New") && item.saleType === "opportunities").length;
  },
  getNewOrdersCount(state) {
    return state.SalesInquiryMap.filter((item: any) => item.so_id && item.so_id.startsWith("New") && item.saleType ==="orders").length;
  },
  getNewQuotesCount(state) {
    return state.SalesInquiryMap.filter((item: any) => item.id && item.id.startsWith("New") && item.saleType === "quotes").length;
  },
  getSalesInquiryMap(state) {
    return state.SalesInquiryMap;
  },
  getLineItemsMap: (state) => (orderId: string) => {
    const index = state.SalesInquiryMap.findIndex((item: any) => item.so_id === orderId)
    if (index > -1) {
      if (state.SalesInquiryMap[index].lineItemsMap) {
        return [...state.SalesInquiryMap[index].lineItemsMap];
      }
    }
    return [];
  },
  getActiveLineItemsTab: (state) => (orderId: string) => {
    const index = state.SalesInquiryMap.findIndex((item: any) => item.so_id === orderId)
    if (index > -1) {
      if ((state.SalesInquiryMap[index] as any).lineItemsActiveTab) {
        return (state.SalesInquiryMap[index] as any).lineItemsActiveTab;
      }
    }
    return 0;
  },
  getOpenedLineItem: (state) => (orderId: string, lineItemId: string) => {
    const index = state.SalesInquiryMap.findIndex((item: any) => item.so_id === orderId)
    if (index > -1) {
     if(state.SalesInquiryMap[index].lis_items) {
      const lineItemIndex = state.SalesInquiryMap[index].lis_items.findIndex((item: any) => item.lis === lineItemId)
      if (lineItemIndex > -1) {
        return state.SalesInquiryMap[index].lis_items[lineItemIndex];
      }
      return {};
     }
    }
    return {};
  },
  getActiveInnerTab: (state) => (orderId: string) => {
    const index = state.SalesInquiryMap.findIndex((order) => order.so_id === orderId)
    if (index > -1) {
      return state.SalesInquiryMap[index].activeInnerTab;
    }
    return 0;
  },
  getActiveInnerTabSuffix: (state, getters) => (orderId: string) => {
    const index = state.SalesInquiryMap.findIndex((order) => order.so_id === orderId)
    if (index > -1) {
      const activeInnerTab = state.SalesInquiryMap[index].activeInnerTab;
      return getters.getInnerTabs[activeInnerTab].suffix
    }
    return '';
  },
  getActiveTab(state) {
    return state.activeTab;
  },
  getActiveOrderTab: (state) => (orderId: string) => {
    const index = state.SalesInquiryMap.findIndex((item: any) => (item.so_id === orderId || item.id === orderId))
    if (index > -1) {
      return state.SalesInquiryMap[index]
    } else {
      return null
    }
  },
  getInnerTabs(){
    return [
      {
        label: "Header",
        prefix: "/sales/orders",
        suffix: ""
      },
      // {
      //   label: "Detail Line Item",
      //   prefix: "/sales/orders",
      //   suffix: "detail-line-item"
      // },
      // {
      //   label: "Summary Line Item",
      //   prefix: "/sales/orders",
      //   suffix: "summary-line-item"
      // },
      {
        label: "Ship-Tax-Credit",
        prefix: "/sales/orders",
        suffix: "ship-tax-credit"
      },
      {
        label: "Tracking",
        prefix: "/sales/orders",
        suffix: "tracking"
      },
      // {
      //   label: "Change History",
      //   prefix: "/sales/orders",
      //   suffix: "change-history"
      // },
      {
        label: "Coupons",
        prefix: "/sales/orders",
        suffix: "coupons"
      },
      {
        label: "Attachments",
        prefix: "/sales/orders",
        suffix: "attachments"
      },
      {
        label: "Export",
        prefix: "/sales/orders",
        suffix: "export"
      },
      {
        label: "Logs",
        prefix: "/sales/orders",
        suffix: "logs"
      }
    ]
  }
}

export const mutations: MutationTree<SalesInquiryState> = {
  PUSH_SALES_INQUIRY_MAP(state, payload: any) {
    const data = {...payload}
    state.SalesInquiryMap = [data, ...state.SalesInquiryMap]
  },
  UPDATE_SALE_INQUIRY(state, payload: any) {
    const data = {...payload}
    let oldId = ''
    if (data.oldId) {
      oldId = data.oldId
      delete data.oldId
    }
    
    const prop = payload.saleType === "orders" ? "so_id" : "id"
    const index = state.SalesInquiryMap.findIndex((item: any) => item[prop] === payload[oldId ? "oldId" : prop])

    if (index >= 0){
      state.SalesInquiryMap[index] = {...data}
      delete state.SalesInquiryMap[index].oldId
    }
  },
  PUSH_LINE_ITEM_MAP(state, payload: {lineItem: LisItems, orderId: number}) {
    const index = state.SalesInquiryMap.findIndex((item: any) => item.so_id === payload.orderId)
    if (index >= 0) {
      const currentElement = state.SalesInquiryMap[index]
      if (!currentElement.lineItemsMap) {
        currentElement.lineItemsMap = []
      }
      currentElement.lineItemsMap = [{...payload.lineItem}, ...currentElement.lineItemsMap]
      state.SalesInquiryMap[index] = currentElement
    }
    
  },
  REMOVE_SALES_INQUIRY_MAP(state, elem: any) {
    const id = elem.so_id || elem.id
    const prop = elem.saleType === "orders" ? "so_id" : "id"
    const index = state.SalesInquiryMap.findIndex((item: any) => (item[prop] === id))
    let idToPush = ''
    let typeToPush = ''
    if (index < 0) return

    if (index === 0 && state.activeTab === 1 && state.SalesInquiryMap.length === 1) {
      state.activeTab = 0;
      router.push({path: `/sales/${elem.saleType}`})
    }
    
    if (state.activeTab < state.SalesInquiryMap.length && state.SalesInquiryMap.length > 1) {
      idToPush = state.SalesInquiryMap[state.activeTab].id ? state.SalesInquiryMap[state.activeTab].id as string : state.SalesInquiryMap[state.activeTab].so_id as string
      typeToPush = state.SalesInquiryMap[state.activeTab].saleType
    }
  
    if (index + 1 === state.activeTab && state.SalesInquiryMap.length === index + 1 && state.SalesInquiryMap.length > 1) {
      state.activeTab = state.activeTab - 1;
      const targetId = state.SalesInquiryMap[index - 1].id ? state.SalesInquiryMap[index - 1].id as string : state.SalesInquiryMap[index - 1].so_id as string
      router.push({path: `/sales/${state.SalesInquiryMap[index - 1].saleType}/${targetId.replaceAll(" ", "%20")}`})
    }
    
    if (index + 1 < state.activeTab) {
      state.activeTab = state.activeTab - 1;
    }
    
    if (index + 1 > state.activeTab && state.activeTab > 0) {
      idToPush = state.SalesInquiryMap[state.activeTab - 1].id ? state.SalesInquiryMap[state.activeTab - 1].id as string : state.SalesInquiryMap[state.activeTab - 1].so_id as string
      typeToPush = state.SalesInquiryMap[state.activeTab - 1].saleType
    }
    
    state.SalesInquiryMap = state.SalesInquiryMap.filter((item: any) => (item[prop] !== id) );

    const data = state.SalesInquiryMap[state.activeTab - 1]

    if (idToPush && typeToPush && data) {
      router.push({path: `/sales/${data.saleType}/${data.id ? data.id as string : data.so_id as string}`})
    } else {
      router.push({path: `/sales`})
    }
  },
  REMOVE_LINE_ITEM_MAP(state, payload: {lineItem: LisItems, orderId: number}) {
    const index = state.SalesInquiryMap.findIndex((order: any) => order.so_id === payload.orderId)
    if (index > -1) {
      state.SalesInquiryMap[index].lineItemsMap = state.SalesInquiryMap[index].lineItemsMap.filter(item => item.lis !== payload.lineItem.lis );
      state.SalesInquiryMap[index].lineItemsActiveTab = 0;
      state.SalesInquiryMap[index].activeInnerTab = 0;
    }
  },
  UPDATE_ACTIVE_TAB(state, tabIndex: number) {
    state.activeTab = tabIndex;
  },
  UPDATE_LINE_ITEM_TAB(state, payload) {
    const index = state.SalesInquiryMap.findIndex((order: any) => order.so_id === payload.orderId)
    if (index > -1) {
      state.SalesInquiryMap[index].lineItemsActiveTab = payload.tabIndex;
    }
  },
  UPDATE_ACTIVE_INNER_TAB(state, payload) {
    const index = state.SalesInquiryMap.findIndex((order: any) => order.so_id === payload.orderId)
    if (index > -1) {
      state.SalesInquiryMap[index].activeInnerTab = payload.tabIndex;
    }
  },
  ADD_REP_TO_ORDER(state, payload) {
    const index = state.SalesInquiryMap.findIndex((order: any) => order.so_id === payload.orderId)
    if (index > -1) {
      if (!state.SalesInquiryMap[index].reps) {
        state.SalesInquiryMap[index].rep_items = []
      }
      state.SalesInquiryMap[index].rep_items.push(payload.rep)
    }
  },
  REMOVE_REP_FROM_ORDER(state, payload) {
    const index = state.SalesInquiryMap.findIndex((order: any) => order.so_id === payload.orderId || order.id === payload.orderId)
    if (index > -1) {
      state.SalesInquiryMap[index].rep_items = state.SalesInquiryMap[index].rep_items.filter((item: any) => item.rep !== payload.rep.rep || item.order_pct !== payload.rep.order_pct ) as any
    }
  }
}

export const actions: ActionTree<SalesInquiryState, RootState> = {
  addRepToOrder({ commit }, payload) {
    commit("ADD_REP_TO_ORDER", {orderId: payload.orderId, rep: payload.rep})
  },
  removeRepFromOrder({ commit }, payload) {
    commit("REMOVE_REP_FROM_ORDER", {orderId: payload.orderId, rep: payload.rep})
  },
  addOpenedSalesInquiry({ commit, state }, payload) {
    const id = payload.so_id || payload.id
    const prop = payload.saleType === "orders" ? "so_id" : "id"
    const index = state.SalesInquiryMap.findIndex((item: any) => (item[prop] === id))
    if (index < 0){
      commit("PUSH_SALES_INQUIRY_MAP", {...payload})
      commit("UPDATE_ACTIVE_TAB", 1)
    } else {
      commit("UPDATE_ACTIVE_TAB", index + 1)
    }
  },
  updateOpenedSalesInquiry({ commit }, payload) {
    commit("UPDATE_SALE_INQUIRY", {...payload})
  },
  addOpenedLineItem({ commit, state }, payload) {
    const index = state.SalesInquiryMap.findIndex((item: any) => item.so_id === payload.orderId)
    if (index >= 0) {
      const isOpened = state.SalesInquiryMap[index].lineItemsMap ? state.SalesInquiryMap[index].lineItemsMap.filter((item: any) => item.lis === payload.lineItem.lis)  : []
      if (isOpened.length === 0){
        commit("PUSH_LINE_ITEM_MAP", {...payload})
        commit("UPDATE_LINE_ITEM_TAB", {orderId: payload.orderId, tabIndex: 1})
      } else {
        const auxIndex = state.SalesInquiryMap[index].lineItemsMap.findIndex((item: any) => item.lis === payload.lineItem.lis)
        commit("UPDATE_LINE_ITEM_TAB", {orderId: payload.orderId, tabIndex: auxIndex + 1})
      }
    }
  },
  removeOpenedSalesInquiry({ commit }, payload) {
    commit("REMOVE_SALES_INQUIRY_MAP", payload)
  },
  removeOpenedLineItem({ commit }, payload) {
    commit("REMOVE_LINE_ITEM_MAP", {...payload})
  },
  changeActiveTab({commit}, tabIndex: number ) {
    commit("UPDATE_ACTIVE_TAB", tabIndex)
  },
  changeLineItemTab({commit}, payload) {
    commit("UPDATE_LINE_ITEM_TAB", payload)
  },
  changeActiveInnerTab({commit}, payload) {
    commit("UPDATE_ACTIVE_INNER_TAB", payload)
  },
  findOpenedSalesInquiry({state}, id) {
    const openedSalesInquiry = state.SalesInquiryMap.filter((item: any) => (item.so_id === id) || (item.id === id))
    if (openedSalesInquiry) {
      return openedSalesInquiry[0]
    }
    return {}
  },
}

export const salesInquiry: Module<SalesInquiryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};