import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ITSO from "@/types/inventoryOrder";

const namespaced = true;

interface PartCheckoutState {
    parts: ITSO[];
}

export const state: PartCheckoutState = {
  parts: [],
};

export const getters: GetterTree<PartCheckoutState, RootState> = {
  getParts: (state) => {
    return state.parts;
  },
  isPartsEmpty: (state) => {
    return state.parts && state.parts.length === 0;
  },
};

export const mutations: MutationTree<PartCheckoutState> = {
  ADD_PART(state, part) {
    const existingPartIndex = state.parts.findIndex((p: ITSO) => {
      return (
        p.rep_id == part.rep_id && 
        p.cust_id == part.cust_id && 
        p.part_id == part.part_id &&
        p.note == part.note
      )
    });

    if(existingPartIndex > -1) { 
      state.parts[existingPartIndex].qty += part.qty;
    } else {
      if(state.parts) {
        state.parts = [...state.parts, part];
      } else {
        state.parts = [part]
      }
    }
  },
  SET_PARTS(state, parts) {
    state.parts = parts;
  },
  REMOVE_PART(state, part) {
    state.parts = state.parts.filter((p: ITSO) => p !== part);
  },
  CLEAR_PARTS(state) {
    state.parts = [];
  },
};

export const actions: ActionTree<PartCheckoutState, RootState> = {
  addPart({commit}, part){
    commit("ADD_PART", part);
  },
  setParts({commit}, parts){
    commit("SET_PARTS", parts);
  },
  removePart({commit}, part){
    commit("REMOVE_PART", part);
  },
  clearParts({commit}){
    commit("CLEAR_PARTS");
  },
};
export const partsCheckout: Module<PartCheckoutState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
