import User from "@/types/user";
import Token from "@/types/token";

export interface Scan {
  prefix: string | null;
  suffix: string| null;
  scanMode: boolean;
  quickScan: boolean;
}


export default interface UserState {
  user: User | null;
  client: string | null;
  loginUrl: string | null;
  loading: boolean;
  token: Token | null;
  subKey: string | null;
  tokenTimeStamp: any;  
  sessionStartDateStamp: any;
  roverJwt: string | null;
  tokenFetchInProgress: Promise<any> | null,
}

export const FETCH_TOKEN = "session/fetchToken";
export const GET_ROVER_JWT = "session/getRoverJwt";
export const GET_SUB_KEY = "session/getSubKey";