import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import ClientAPI from "./ClientAPI";

export default class CommitService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getCommit(part: any, client: string, rangeStart?: number, rangeEnd?: number) {
    const params = {
      rangeStart,
      rangeEnd,
      client,
      part,
    };
    return ClientAPI.Get(`commits`, params)
  }
}
