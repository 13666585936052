import { AxiosInstance } from "axios";
import axios from 'axios';
import store from "../store"
import ClientAPI from "./ClientAPI";

export default class ContactService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false,
        })
    }

    async searchContacts(selection: string, Client: any) {
        const params = {
            selection,
            Client,
        }
        return ClientAPI.Get(`contacts`, params)
    }

    async getContacts(ids: any, Client: any) {
        const params = {
            ids,
            Client,
        }
        return ClientAPI.Get(`contacts`, params)
    }

    async resetPwd(payload: any) {
        return ClientAPI.Post(`reset`, payload)
    }

    async newContact(payload: any) {
        const { cust_id, portal, type, client, contact_id, contact, oldContact } = payload;

        const params = {
            client,
            portal,
            type,
            contact_id
        }
        return ClientAPI.Post(`contacts/${cust_id}`, { contact, oldContact }, params)
    }

    async updateContact(oldContact: any, contact: any, client: string) {

        const payload = {
            oldContact,
            contact
        };

        const params = {
            client
        }
        return ClientAPI.Put(`contacts`, payload, params)
    }

}
