import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI, { DEFAULT_CLIENT_SUCCESS_HANDLER } from "./ClientAPI";
import { NotificationType } from "@/types/notification";

export default class CashService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }



  async getCash(id?: string, cust?: string, postDateStart?: string, postDateEnd?: string, rangeStart?: number, rangeEnd?: number, sortBy?: string, sortOrder?: string, reg?: string, paymentType?: string) {
    const params = {
        id,
        cust,
        postDateStart,
        postDateEnd,
        rangeStart,
        rangeEnd,
        sortBy,
        sortOrder,
        reg,
        paymentType
    };
    const error = () => ClientAPI.displayNotification(NotificationType.ERROR, "Error fetching cash data");
    return ClientAPI.Get(`cash`, params, DEFAULT_CLIENT_SUCCESS_HANDLER, error)
  }

  async updateCash(cash_id: any, newCash: any, oldCash: any) {
    const payload = {
        newCash,
        oldCash
    };
    const error = () => ClientAPI.displayNotification(NotificationType.ERROR, "Error updating cash record");
    const success= () => ClientAPI.displayNotification(NotificationType.SUCCESS, "Successfully voided transaction")
    return ClientAPI.Put(`cash/${cash_id}`, payload, "", success, error)
  }

  
}
