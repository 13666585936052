import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import PriceControlState, { PriceCodeItem } from "@/types/controls/price";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: PriceControlState = {
  code_items: [],
  control_id: "",
};

export const getters: GetterTree<PriceControlState, RootState> = {
  getPriceCodes: (state) => {
    return state.code_items;
  },
  isPriceCodeLocked: (state) => (code: string) => {
    if(code) {
      code = code.toLowerCase()
    } else {
      code = ""
    }
    return state.code_items.find((item: PriceCodeItem) => {
      if(item?.code) {
        return item?.code?.toLowerCase() === code
      }
      return false
    })?.lock_price === "Y";
  }
};

export const mutations: MutationTree<PriceControlState> = {
  SET_PRICE_CONTROL(state, {code_items, control_id}) {
    state.code_items = code_items;
    state.control_id = control_id;
  },
};

export const actions: ActionTree<PriceControlState, RootState> = {
  async getPriceControl({ commit, state }, {client, refresh}) {
    return new Promise((resolve, reject) => {
      if (state.code_items?.length > 0 && !refresh) {
        resolve(state);
      } else {
        controlService
          .getControl(
            "PRICE",
            "PRICE.CONTROL",
            "CONTROL",
            "",
            client
          )
          .then((resp: any) => {
            if (resp && resp.length > 0) {
              if (resp.length > 0) {
                commit("SET_PRICE_CONTROL", {
                  code_items: resp[0].code_items || [],
                  control_id: resp[0].control_id || "",
                });
                resolve(resp[0]);
              } else {
                reject("No data returned");
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const priceControl: Module<PriceControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
