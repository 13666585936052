import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex sidebar-link max-w-full text-base" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.menu.to,
    class: "text-700 p-2 border-round mb-0",
    style: {"text-decoration":"none"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.menu.imgIcon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageName,
              width: "32",
              height: "32",
              class: "sidebar-icon"
            }, null, 8, _hoisted_2))
          : _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 1,
              class: _normalizeClass(_ctx.iconClass)
            }, null, 2)), [
              [_directive_tooltip, _ctx.menu.label]
            ]),
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["to"]))
}