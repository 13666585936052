import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SoQuotesState from "@/types/state/soQuotes";
import SOQuote from "@/types/soquote";

import SoQuotesService from "@/services/SOQuotesService";
import SOQuotePDfView from "@/types/soquotepdf";

const namespaced = true;

const soquotesService = new SoQuotesService(
  process.env.VUE_APP_ABSTRACTION_API
);

const SOQUOTE: SOQuote = {
    id: "",
    load: "",
    date: "",
    prospect: "",
    customer: "",
    name: "",
    phone: "",
    contact: "",
    status: "",
    quoted_by: "",
    email: "",
    fax: "",
    valid_thru: "",
    status_date: "",
    exchange_rate: "",
    cost_method: "",
    est_close: "",
    code: "",
    terms: "",
    li_items: [],
    address_items: [],
    rev: "",
    co_code: "",
    resale_no: "",
    rep_items: [],
    change_date_items: [],
    notes: "",
    stamp_user: "",
    stamp_date: "",
    ext: "",
    reason_code: "",
    contact_id: "",
    currency_code: "",
    conf_notes: ""
};

const instantiateQuoteFields = (quote: SOQuote) => {
    for (const [key, value] of Object.entries(SOQUOTE)) {
      if (!(key in quote)) {
        Object.assign(quote, {
          [key]: value,
        });
      }
    }
  };

export const state: SoQuotesState = {
  soQuotes: null,
  page: 1,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
  quotePDFs: [],
  loadingQuotes: [],
};

export const getters: GetterTree<SoQuotesState, RootState> = {
  getSoQuotes: (state) => {
    return state.soQuotes;
  },
  getQuote(state): Array<SOQuotePDfView> {
    return state.quotePDFs;
  },
  getLoadingQuotes(state) {
    return state.loadingQuotes;
  },
  getRangeStart: (state) => {
    return state.rangeStart;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
  getCurrPage: (state) => {
    return state.page;
  },
};

export const mutations: MutationTree<SoQuotesState> = {
  SET_SO_QUOTES_PDF(state, obj) {
    state.quotePDFs.push(obj);
  },
  REMOVE_PDF_BY_ID(state, id) {
    state.quotePDFs = state.quotePDFs.filter((item) => item.id !== id);
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  SET_NEXT_PAGE(state, page) {
    state.page = page;
  },
  SET_NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  ADD_ID_LOADING(state, id) {
    state.loadingQuotes.push(id);
  },
  REMOVE_ID_LOADING(state, id) {
    const index = state.loadingQuotes.indexOf(id, 0);
    if (index > -1) {
      state.loadingQuotes.splice(index, 1);
    }
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  CLEAR_SO_QUOTES(state) {
    state.soQuotes = null;
  },
};

export const actions: ActionTree<SoQuotesState, RootState> = {
  fetchSoQuotes(
    { commit, dispatch },
    {
      ids,
      custs,
      status,
      dateStart,
      dateEnd,
      quotedBy,
      sortBy,
      sortOrder,
      addSoQuote,
      correls
    }
  ) {
    if (!addSoQuote) {
      commit("RESET_PAGE");
    }
    return new Promise((resolve, reject) => {
      soquotesService
        .getSOQuotes(
          ids,
          custs,
          state.rangeStart,
          state.rangeEnd,
          status,
          dateStart,
          dateEnd,
          quotedBy,
          sortBy,
          sortOrder, 
          correls
        )
        .then((response: any) => {
          if (!addSoQuote) {
            commit("CLEAR_SO_QUOTES");
            commit("SET_FIRST_ROW", 0);
          }
          response.soquote_items.forEach((element: any) => {
            instantiateQuoteFields(element)
          }) 
          commit("SET_NEXT_RANGE");
          resolve({ success: true, data: response.soquote_items});
        })
        .catch((error) => {
          reject({ success: false });
        });
    });
  },
  setFirstRow({ commit }, row) {
    commit("SET_FIRST_ROW", row);
  },
  removeQuotePDF({ commit }, id) {
    commit("REMOVE_PDF_BY_ID", id);
  },
  async getQuotePDF({ commit, dispatch, state }, payload) {
    const pdf = state.quotePDFs.find(
      (quote: SOQuotePDfView) => quote.id === payload.recordId
    );
    if (pdf !== undefined) {
      window.open(pdf.pdf, "_blank");
    } else {
      commit("ADD_ID_LOADING", payload.recordId);
      await soquotesService
        .getQuote(payload.recordId)
        .then((resp: any) => {
          const bufferArray = base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
          commit("SET_SO_QUOTES_PDF", { id: payload.recordId, pdf: data });
          commit("REMOVE_ID_LOADING", payload.recordId)
        })
        .catch((error) => {
          commit("REMOVE_ID_LOADING", payload.recordId);
        });
    }
  },
  clearAndResetQuotes({ commit }) {
    commit("CLEAR_SO_QUOTES");
    commit("RESET_PAGE");
  },
};

export const soQuotes: Module<SoQuotesState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};

function base64ToArrayBuffer(data: string) {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
