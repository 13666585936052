import { AxiosInstance } from "axios";
import axios from "axios";

export default class TokenService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async fetchToken() {
    const response = await this.client.post("token",);
    return response.data;
  }
}
