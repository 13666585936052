import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"font-size":"12px"},
  class: "col-12 col-offset-1 text-center footer"
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" Copyright © 2021-" + _toDisplayString(_ctx.current) + " Zumasys Inc. ", 1),
    _createElementVNode("a", {
      href: `https://docs.zumasys.com/rover/release-notes/web/${_ctx.version.replaceAll('.', '-')}/`,
      target: "_blank"
    }, " v" + _toDisplayString(_ctx.version), 9, _hoisted_2)
  ]))
}