import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";

export default class SOQuotesService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getSOQuotes(
    ids?: string,
    customer?: string,
    rangeStart?: number,
    rangeEnd?: number,
    status?: string,
    dateStart?: string,
    dateEnd?: string,
    quotedBy?: string,
    sortBy?: string,
    sortOrder?: string,
    correls?: string
  ) {
    const params: any = {
      cust: customer,
      status,
      ids,
      dateStart,
      dateEnd,
      quotedBy,
      sortBy,
      sortOrder,
      correls
    };

    if (!ids || ids == "") {
      params["rangeStart"] = rangeStart;
      params["rangeEnd"] = rangeEnd;
    }
    return ClientAPI.Get(`sales/quotes`, params);
  }
  async postSOQuote(payload: any) {
    return ClientAPI.Post(`sales/quotes`, payload)
  }
  async putSOQuote(payload: any) {
    const { quoteId, newQuote, oldQuote } = payload;

    return ClientAPI.Put(`sales/quotes/${quoteId}`, { newQuote, oldQuote })

  }

  async SOQuotePdf (id: string, email?: string, modelNo?: string, prices?: string) {
    const params = {
      id,
      email,
      modelNo,
      prices
    };
    return ClientAPI.Get(`sales/quotes/${id}/pdf`, params)
  }

  async getQuote(key: string, data?: any) {
    return ClientAPI.Get(`sales/quotes/${key}/pdf`, data)
  }
}
